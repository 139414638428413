<template>
  <div>
    <div class="content">
      <p class="selected-text1" v-if="selectionMessage1">
        {{ selectionMessage1 }}
      </p>

      <div class="create-segmentation-container">
        <q-input v-model="newSegmentation" class="create-segmentation-input" filled square :label="$t('Create a new segmentation')" />
        <q-btn class="create-segmentation-button" :color="'secondary'" @click="openDialog('create-segmentation')">
          Create
          <q-icon name="add" class="add-icon" />
        </q-btn>
      </div>

      <div class="create-segmentation-container">
        <q-select
          v-model="tmpSelectedSegmentationId"
          class="create-segmentation-input"
          filled
          square
          :label="$t('Select a segmentation')"
          emit-value
          map-options
          :options="mappedSegmentationsList"
          option-value="id"
          option-label="label"
        />
        <q-btn class="create-segmentation-button" :color="'secondary'" @click="openDialog('delete-segmentation')">
          Delete
          <q-icon name="delete" />
        </q-btn>
      </div>

      <div class="ctrl-container">
        <q-btn class="segmentation-button" :color="'secondary'" @click="openDialog('apply-segmentation')">
          Apply segmentation
        </q-btn>
        <q-btn class="segmentation-button" :color="'secondary'" @click="saveFilters">
          Save Filters
        </q-btn>
      </div>
    </div>

    <p class="selected-text2" v-if="selectedSegmentation">
        {{ selectionMessage2 }}
      </p>
    <div class="content">
      <p class="drawerTitle">
        Families and sub-families
        <q-icon name="add" class="add-icon" @click="openDialog('create-family')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation">
        <q-btn class="item-type1" :color="'secondary'" @click="openDialog('select-family')">Select family</q-btn>
        <div v-for="family in this.$store.state.segmentation.history.families" :key="family.id" class="item-type1" @click="openDialog('apply-family', family)">
          <p>{{ family.label }}</p>
          <q-tooltip>
            {{family.label}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeFamilie(family.id)" />
          <img :src="family.img" class="inline-picture" @error="handleImageError" />
        </div>
      </div>
    </div>

    <div class="content">
      <p class="drawerTitle">
        Added values
        <q-icon name="add" class="add-icon" @click="openDialog('create-value')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-value')" style="padding:0; font-size: 0.7em;">Select value</q-btn>
        <div v-for="value in this.$store.state.segmentation.history.values" :key="value.id" class="item-type2" @click="openDialog('apply-value', value)">
          <p>{{ localized(value.label['en-US']) }}</p>
          <q-tooltip>
            {{localized(value.label['en-US'])}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeValue(value.id)" />
        </div>
      </div>
    </div>

    <div class="content" style="border:none;">
      <p class="drawerTitle">
        Material
        <q-icon name="add" class="add-icon" @click="openDialog('create-material')" />
      </p>
      <div class="itemContainer" v-if="selectedSegmentation">
        <q-btn class="item-type2" :color="'secondary'" @click="openDialog('select-material')" style="padding:0; font-size: 0.7em;">Select material</q-btn>
        <div v-for="material in this.$store.state.segmentation.history.material" :key="material.id" class="item-type2" @click="openDialog('apply-material', material)">
          <p>{{ localized(material.label['en-US']) }}</p>
          <q-tooltip>
            {{localized(material.label['en-US'])}}
          </q-tooltip>
          <q-icon name="close" class="close-icon" @click.stop="removeMaterial(material.id)" />
        </div>
      </div>
    </div>

    <CreateFamilyDialog
      :model="showCreateFamilyDialog"
      @update:model="showCreateFamilyDialog = $event"
      @confirm="confirmAction"
    />

    <SelectFamilyDialog
      :selectedFamily="selectedFamily"
      :model="showSelectFamilyDialog"
      @update:model="showSelectFamilyDialog = $event;"
      @confirm="confirmAction"
    />

    <CreateAttrDialog
      :model="showCreateAttrDialog"
      :selected="selected"
      :set="createAttrSet"
      @update:model="showCreateAttrDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <SelectValuesDialog
      :model="showSelectValuesDialog"
      :selected="selected"
      :attributes="attributes"
      @update:model="showSelectValuesDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <SelectMaterialDialog
      :model="showSelectMaterialDialog"
      :selected="selected"
      :attributes="attributes"
      @update:model="showSelectMaterialDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

    <ConfirmDialog
      :model="showConfirmDialog"
      :text="confirmDialogText"
      @update:model="showConfirmDialog = $event"
      @update:records="updateRecords"
      @confirm="confirmAction"
    />

  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import localization from '../../../lib/mixins'; //eslint-disable-line
import CreateFamilyDialog from './dialog/CreateFamilyDialog.vue';
import SelectFamilyDialog from './dialog/SelectFamilyDialog.vue';
import CreateAttrDialog from './dialog/CreateAttrDialog.vue';
import SelectValuesDialog from './dialog/SelectValuesDialog.vue';
import SelectMaterialDialog from './dialog/SelectMaterialDialog.vue';
import ConfirmDialog from './dialog/ConfirmDialog.vue';

export default {
  mixins: [localization],
  props: ['data', 'selected'],
  components: {
    CreateFamilyDialog,
    SelectFamilyDialog,
    SelectValuesDialog,
    CreateAttrDialog,
    SelectMaterialDialog,
    ConfirmDialog,
  },
  data() {
    return {
      locale: this.$store.state.auth.locale,

      showCreateFamilyDialog: false,
      showSelectFamilyDialog: false,
      showCreateAttrDialog: false,
      showSelectValuesDialog: false,
      showSelectMaterialDialog: false,
      showConfirmDialog: false,

      createAttrSet: null,
      createAttrType: '',
      selectedFamily: null,
      selectedValue: null,
      selectedMaterial: null,
      confirmDialogText: '',

      actionType: '',
      filters: [],
      currentFilterId: null,
      newSegmentation: '',
      tmpSelectedSegmentationId: null,
    };
  },
  computed: {
    ...mapState('segmentation', [
      'selectedSegmentation',
      'segmentationsList',
      'history',
    ]),
    defs() {
      const { defs } = this.$store.state.pricing.defs;
      return Object.entries(defs)
        .filter(([, attr]) => !this.data.data.columns.includes(attr.name))
        .map(([name, attr]) => ({
          label: `${attr.type} --- ${this.localized(attr.label)}`,
          value: attr.name,
          id: name,
        }));
    },
    sets() {
      const { sets } = this.$store.state.pricing.sets;
      return Object.keys(sets)
        .map((key) => sets[key])
        .filter((set) => set.name === 'materials' || set.name === 'features');
    },
    attributes() {
      return Object.values(this.$store.state.pricing.defs.defs);
    },
    ...mapGetters('segmentation', [
      'mappedSegmentationsList',
    ]),
    selectedSegmentationId() {
      return this.selectedSegmentation ? this.selectedSegmentation.id : null;
    },
    selectionMessage1() {
      if (this.selectedSegmentation === null) {
        return 'Please select a segmentation';
      }
      return null;
    },
    selectionMessage2() {
      return this.selected.length ? `${this.selected.length} Parts selected` : 'No parts selected';
    },
  },
  watch: {
    selectedSegmentation() {
      this.tmpSelectedSegmentationId = this.selectedSegmentation;
    },
    tmpSelectedSegmentationId() {
      if (this.tmpSelectedSegmentationId) {
        this.$store.state.segmentation.selectedSegmentation = this.tmpSelectedSegmentationId;
      } else {
        this.$store.state.segmentation.selectedSegmentation = this.$store.state.segmentation.defaultSegmentation.id;
      }
      this.updateSegmentation();
    },
  },
  methods: {
    async saveFilters() {
      await this.$store.dispatch('segmentation/saveFilters');
    },

    async updateSegmentation() {
      console.log('updateSegmentation', this.tmpSelectedSegmentationId);
      const selectedSegmentation = await this.$store.dispatch('segmentation/updateSegmentation');

      console.log('selectedSegmentation', selectedSegmentation);
      if (selectedSegmentation && selectedSegmentation.name) {
        const label = selectedSegmentation.name;
        this.$store.commit('header/setHeader', {
          title: `Catalog - Segmentation: ${label}`,
        });
      }
      this.$forceUpdate();

      // TODO: Most of this should be moved to the store
      console.log('[Segmentation Drawer] selectedSegmentation', selectedSegmentation.filter);
      if (selectedSegmentation.filter) {
        const filterId = selectedSegmentation.filter;
        const { filters } = this.$store.state.segmentation;
        let filter = filters.find((f) => f.id === filterId);
        filter = filter?.filter.filters;
        if (filter) {
          this.$store.state.catalog.filters = filter;
          console.log('[Segmentation Drawer] Applying filters', filter);
          this.$store.commit('catalog/setFilters', filter);
        }
      } else {
        this.$store.commit('catalog/resetFilters');
      }

      this.$emit('update');
    },

    handleImageError(event) {
      event.target.src = '/default.jpg';
    },
    updateRecords(name) {
      console.log('updateRecords', name);
      this.updateSegmentation();
    },

    checkBeforeDialog() {
      if (this.selected.length === 0) {
        this.showNotification('negative', 'Please select at least one part', 'warning');
        return false;
      }
      return true;
    },
    openDialog(actionType, item = null) {
      console.log('openDialog', actionType, item);
      if (
        actionType !== 'create-segmentation'
        && actionType !== 'delete-segmentation'
        && actionType !== 'apply-segmentation'
        && actionType !== 'create-family'
        && actionType !== 'select-family'
        && actionType !== 'create-value'
        && actionType !== 'create-material') {
        if (!this.checkBeforeDialog()) return;
      }

      const { filters } = this.$store.state.catalog;
      console.log('segmentationsList', this.segmentationsList);
      const segmentationLabel = this.segmentationsList.find((s) => s.id === this.selectedSegmentation)?.name;
      const materialSet = this.sets.find((set) => set.name === 'materials');
      const valueSet = this.sets.find((set) => set.name === 'features');

      this.actionType = actionType;
      switch (actionType) {
        // Segmentation actions
        case 'create-segmentation':
          if (filters.length > 0) {
            this.confirmDialogText = `You are about to create a new segmentation "${this.newSegmentation}" with the ${filters.length} current filters, are you sure?`;
          } else {
            this.confirmDialogText = `You are about to create a new segmentation "${this.newSegmentation}" with the current filters, are you sure?`;
          }
          this.showConfirmDialog = true;
          break;
        case 'delete-segmentation':
          this.confirmDialogText = `You are about to delete the ${segmentationLabel} segmentation, are you sure?`;
          this.showConfirmDialog = true;
          break;
        case 'apply-segmentation':
          this.confirmDialogText = `You are about to merge the ${segmentationLabel} segmentation in the DEFAULT segmentation, are you sure?`;
          this.showConfirmDialog = true;
          break;
        // Family actions
        case 'create-family':
          this.showCreateFamilyDialog = true;
          break;
        case 'select-family':
          this.showSelectFamilyDialog = true;
          break;
        case 'apply-family':
          this.selectedFamily = item.id;
          this.confirmDialogText = `You are about to move the ${this.selected.length} selected parts to the family "${item.label}", are you sure?`;
          this.showConfirmDialog = true;
          break;
          // Value actions
        case 'create-value':
          this.createAttrType = 'value';
          if (materialSet) {
            this.createAttrSet = valueSet.id;
            console.log('create-value', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-value':
          this.showSelectValuesDialog = true;
          break;
        case 'apply-value':
          this.selectedValue = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
          // Material actions
        case 'create-material':
          this.createAttrType = 'material';
          if (materialSet) {
            this.createAttrSet = materialSet.id;
            console.log('create-material', this.createAttrSet);
            this.showCreateAttrDialog = true;
          } else {
            console.error('Material set not found');
          }
          break;
        case 'select-material':
          this.showSelectMaterialDialog = true;
          break;
        case 'apply-material':
          this.selectedMaterial = item.id;
          this.confirmDialogText = `You are about to add the attribute "${this.localized(item.label)}" to the ${this.selected.length} selected parts, are you sure?`;
          this.showConfirmDialog = true;
          break;
        default:
          break;
      }
    },

    async addHistoryFamilies(familyId) {
      this.$store.dispatch('segmentation/addHistoryFamilies', { familyId, selectedSegmentation: this.selectedSegmentation });
    },
    async updateFamily() {
      this.$store.dispatch('segmentation/updateFamily');
    },
    removeFamilie(familyId) {
      this.$store.dispatch('segmentation/removeHistoryFamilie', { familyId, selectedSegmentation: this.selectedSegmentation });
    },
    addHistoryValues(params) {
      this.$store.dispatch('segmentation/addHistoryValues', params);
    },
    removeValue(valueId) {
      this.$store.dispatch('segmentation/removeHistoryValue', valueId);
    },
    addHistoryMaterial(params) {
      this.$store.dispatch('segmentation/addHistoryMaterial', params);
    },
    removeMaterial(materialId) {
      this.$store.dispatch('segmentation/removeHistoryMaterial', materialId);
    },

    confirmAction(data) {
      if (data.actionType) this.actionType = data.actionType;
      console.log('confirmAction', this.actionType, data);

      // segmentation actions
      if (this.actionType === 'delete-segmentation') {
        this.deleteSegmentation();
      } else if (this.actionType === 'create-segmentation') {
        this.createSegmentation();
      } else if (this.actionType === 'apply-segmentation') {
        this.applySegmentation();
      // Family actions
      } else if (this.actionType === 'create-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
      } else if (this.actionType === 'select-family') {
        this.move(this.selected, data.selectedFamily);
        this.addHistoryFamilies(data.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'apply-family') {
        this.move(this.selected, this.selectedFamily);
        this.updateFamily();
      } else if (this.actionType === 'delete-family') {
        this.deleteFamily(data.toDelete);
        // Value actions
      } else if (this.actionType === 'create-value') {
        const selectedParts = this.selected;
        this.updateSegmentation();
        if (data.attrId) {
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryValues(params);
        }
        this.updateSegmentation();
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-value') {
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryValues(data.params);
        }
      } else if (this.actionType === 'apply-value') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedValue);
        this.addMaterial(partsIds, params);
      // Material actions
      } else if (this.actionType === 'create-material') {
        const selectedParts = this.selected;
        this.updateSegmentation();
        if (data.attrId) {
          console.log('apply new material to parts');
          console.log('data', data.attrId);
          this.selectedMaterial = data.attrId;
          const partsIds = selectedParts.map((p) => p.id);
          const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
          this.addMaterial(partsIds, params);
          this.addHistoryMaterial(params);
        }
        this.updateSegmentation();
        this.showCreateAttrDialog = false;
      } else if (this.actionType === 'select-material') {
        // Done inside the dialog
        if (data.params) {
          const partsIds = this.selected.map((p) => p.id);
          this.addMaterial(partsIds, data.params);
          this.addHistoryMaterial(data.params);
        }
      } else if (this.actionType === 'apply-material') {
        const partsIds = this.selected.map((p) => p.id);
        const params = this.makeMaterialParams(partsIds, this.selectedMaterial);
        this.addMaterial(partsIds, params);
      }

      this.showConfirmDialog = false;
      this.actionType = '';
    },

    async deleteFamily(familyId) {
      this.$store.dispatch('segmentation/deleteFamily', familyId);
      this.updateSegmentation();
    },

    async move(parts, familyId) {
      this.$store.dispatch('segmentation/move', { parts, familyId });
      this.updateSegmentation();
    },

    addValues() {
      console.log('addValues', this.selectedValue);
    },

    makeMaterialParams(ids, materialId) {
      const attributesValues = this.attributes.reduce((res, att) => {
        res[att.id] = {
          ...att,
          ...(att.type === 'number' ? { number: null, fixed: null, unit: '' } : {}),
          ...(att.type === 'boolean' ? { checked: att.id === materialId } : {}),
        };
        return res;
      }, {});

      const booleans = Object.keys(attributesValues)
        .filter((att) => attributesValues[att].checked)
        .map((filteredAttribute) => ({ [attributesValues[filteredAttribute].name]: attributesValues[filteredAttribute].checked }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      const numbers = Object.keys(attributesValues)
        .filter((attribute) => attributesValues[attribute].number != null)
        .map((filteredAttribute) => ({
          [attributesValues[filteredAttribute].name]: {
            number: Number(attributesValues[filteredAttribute].number),
            fixed: `${attributesValues[filteredAttribute].number}`,
            unit: attributesValues[filteredAttribute].unit,
          },
        }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      console.log('booleans', booleans);
      console.log('numbers', numbers);
      const params = {
        ...booleans,
        ...numbers,
      };

      return params;
    },
    async addMaterial(ids, params) {
      const res = await this.$store.dispatch('segmentation/addMaterial', { ids, params });
      this.$emit('confirm', res);
      this.updateSegmentation();
    },

    async applySegmentation() {
      await this.$store.dispatch('segmentation/applySegmentation', this.selectedSegmentation);
    },
    async deleteSegmentation() {
      await this.$store.dispatch('segmentation/deleteSegmentation', this.selectedSegmentation);
      this.$forceUpdate();
    },
    async createSegmentation() {
      await this.$store.dispatch('segmentation/createSegmentation', this.newSegmentation);
      this.newSegmentation = '';
      this.$forceUpdate();
      this.updateSegmentation();
    },
    showNotification(type, message, icon) {
      this.$q.notify({ type, message, icon });
    },
  },

  async created() {
    this.$store.state.catalog.segmentationDrawerIsOpen = true;
    await this.$store.dispatch('segmentation/init', this.showNotification);
    this.updateSegmentation();
  },
  async beforeDestroy() {
    this.$store.state.catalog.segmentationDrawerIsOpen = false;
  },
};
</script>

<style lang="stylus" scoped>
.create-segmentation-container {
  display: flex;
  align-items: center;
  width: 100%;
  //border:1px solid red;
  margin-bottom: 1em;
  padding: 0 1em;
}

.create-segmentation-input{
  flex: 1;
}

.create-segmentation-button {
  margin-left: 1em;
  height: 50px;
  width:50px;
  font-size: 0.8em;
}

.ctrl-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  //flex-wrap: wrap;
  align-items: center;
  //margin-bottom: 1em;
  padding: 0 1em;
}

.segmentation-button{
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.84em;
  height: 50px;
}

.selected-text1
.selected-text2{
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom:0.5em;
  color: #ff8700;
  width: 100%;
  text-align: center;
}
.selected-text2{
  margin-top: 0.5em;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top:  1em;
  border-bottom: 1px solid #ccc;
}

.drawerTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  width: 100%;
  text-align: center;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}

.add-icon{
  cursor: pointer;
  font-size:1.4em;
}
.add-icon:hover {
  transform: scale(1.2);
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px !important;
  overflow-y: scroll;
  padding: 0 1em;
}

.item-type1
.item-type2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  height: 100px;
  width:75px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 8px;
  &:hover {
    background-color: #f0f0f0;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    width: 100%;
  }

  img {
    height: 50px;
    border: 1px solid #ccc;
    object-fit: contain;
    border-radius: 5px;
  }
}

.item-type1{
  flex-direction: column;
  position : relative;
  .close-icon {
    position: absolute;
    top: 3px;
    right:2px;
  }
}

.item-type2 {
  flex-direction: row;
  justify-content: center
  height: 40px;
  width: 115px;

  p {
    text-align: center;
    font-size: 0.7em;
    font-weight: bold;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 8px;
  &:hover {
    color: red;
  }
}
</style>
