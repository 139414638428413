<template>
  <q-dialog v-model="localModel" prevent-close @ok="onOk" @cancel="onCancel" @show="onShow" @hide="onHide">

    <q-card class="dialog">

      <q-card-section class="row items-center">
        <q-toolbar class="bg-primary shadow2 text-white">
          <q-toolbar-title>{{$t('Select a value')}}</q-toolbar-title>
        </q-toolbar>
      </q-card-section>

      <q-card-section class="lg-section-content ">

          <div class="attr-container" v-for="(attribute, index) in attrsBySet[getFeature.id]" :key="'attribute-option-' + index">
            <div class="boolean-attribute-container" v-if="attribute.type === 'boolean'">
              <div class="attribute-information">
                <q-checkbox :label="localized(attribute.label['en-US'])" v-model="attributesValues[attribute.id].checked" @input="propagate"/>
              </div>
              <div class="weights-container">
                <div v-for="(w, index) in filteredWeights(attribute.weight)" :key="'weight-'+ index">
                  <span class="lg-sparkline-set">Type:</span>
                  <span class="weight-value">{{ w.type }}</span>
                  <span class="lg-sparkline-set">Number:</span>
                  <span class="weight-value">{{ w.v.number }} {{ w.v.unit }}</span>
                </div>
              </div>
            </div>
            <div class="test1" v-else-if="attribute.type === 'number'">
              <span class="test2">{{ attribute.label['en-US'] }}</span>
              <q-input dense filled square type="number" v-model="attributesValues[attribute.id].number" :suffix="attribute && attribute.v && attribute.v.unit?data.v.unit:''"/>
            </div>
          </div>

      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat color="secondary" v-close-popup>
          {{$t('Cancel')}}
        </q-btn>
        <q-btn flat color="positive" v-close-popup @click="pathProductsValues">
          {{$t('Confirm')}}
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { localization } from '../../../../lib/mixins';

export default {
  props: {
    model: Boolean,
    selected: Array,
    attributes: Array,
  },
  mixins: [localization],
  data() {
    return {
      localModel: this.model,
      attributesValues: this.attributes.reduce((res, att) => {
        res[att.id] = {
          ...att,
          ...(att.type === 'number' ? { number: null, fixed: null, unit: '' } : {}),
          ...(att.type === 'boolean' ? { checked: false } : {}),
        };
        return res;
      }, {}),
    };
  },
  computed: {
    sets() {
      const { sets } = this.$store.state.pricing.sets;
      return Object.keys(sets)
        .map((key) => sets[key])
        .filter((set) => set.name === 'materials' || set.name === 'features');
    },
    attrsBySet() {
      return this.attributes.reduce((acc, attribute) => {
        if (!acc[attribute.set]) {
          acc[attribute.set] = [];
        }
        acc[attribute.set].push(attribute);
        return acc;
      }, {});
    },
    filteredWeights() {
      return (weights) => weights.filter((w) => w.v);
    },
    getMaterial() {
      console.log('this.attributes', this.attributes);
      const material = this.sets.find((s) => s.name === 'materials');
      console.log('materials', material);
      return material;
    },
    getFeature() {
      console.log('this.attributes', this.attributes);
      const feature = this.sets.find((s) => s.name === 'features');
      console.log('feature', feature);
      return feature;
    },
  },
  watch: {
    model(newVal) {
      this.localModel = newVal;
    },
    localModel(newVal) {
      this.$emit('update:model', newVal);
    },
  },
  methods: {
    onOk(data) {
      this.$emit('confirm', data);
    },
    onCancel() {
      this.localModel = false;
    },
    onShow() {},
    onHide() {
      this.localModel = false;
    },
    formattedLabel(label) {
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    propagate(value) {
      this.$emit('change', value);
    },
    async pathProductsValues() {
      const ids = this.selected.map((p) => p.id);

      const booleans = Object.keys(this.attributesValues)
        .filter((att) => this.attributesValues[att].checked)
        .map((filteredAttribute) => ({ [this.attributesValues[filteredAttribute].name]: this.attributesValues[filteredAttribute].checked }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      const numbers = Object.keys(this.attributesValues)
        .filter((attribute) => this.attributesValues[attribute].number != null)
        .map((filteredAttribute) => ({
          [this.attributesValues[filteredAttribute].name]: {
            number: Number(this.attributesValues[filteredAttribute].number),
            fixed: `${this.attributesValues[filteredAttribute].number}`,
            unit: this.attributesValues[filteredAttribute].unit,
          },
        }))
        .reduce((prevObj, currentObj) => ({ ...prevObj, ...currentObj }), { ids });

      const params = {
        ...booleans,
        ...numbers,
      };

      const res = { params };
      this.$emit('confirm', res);

      this.attributesValues = this.attributes.reduce((res2, att) => {
        res2[att.id] = {
          ...att,
          ...(att.type === 'number' ? { number: null, fixed: null, unit: '' } : {}),
          ...(att.type === 'boolean' ? { checked: false } : {}),
        };
        return res;
      }, {});
    },
  },
};
</script>

<style scoped lang="stylus">
.dialog{
  min-width : 500px;
  width: 100%;
  max-width: 50%;
  max-height: 80%;
  overflow: hidden;
}

.lg-section-content{
  //min-width: 550px
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;

  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
   justify-content: flex-start;
  // align-items: flex-start;
}

.attr-container{
   //border: 1px solid blue;
  // width:100%;
}

.choose-values-message{
  margin: 20px
}

.separator{
  height: 1px
  background-color: $grey-5
  width: 100%
}

.q-checkbox{
  flex-grow: 0
}

.lg-sparkline-set{
  background $grey-4
  width 60px
  margin: 5px
  padding: 5px
}

.boolean-attribute-container{
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid #ccc
  padding-bottom: 10px
  margin-bottom: 10px
}

.attribute-information{
  display: flex
  flex-direction: row
  align-items: center
}

.weights-container {
  display: flex
  flex-direction: column
}

.weights-container > div {
  display: flex;
  align-items: center
  justify-content: flex-start
}

.weight-value{
  width: 80px
  flex-grow: 2
}

.lg-sparkline-set, .weight-value {
  flex: 1
  text-align: center
}

.test1 {
  display: flex
  align-items: center
  justify-content: space-between
}

.test2 {
  margin: 20px
}
</style>
