<template>
  <q-dialog
    v-model="localModel"
    prevent-close
    @ok="onOk"
    @cancel="onCancel"
    @show="onShow"
    @hide="onHide"
  >
      <q-card class="dialog">

          <q-card-section class="row items-center">
            <q-toolbar class="bg-primary shadow2 text-white">
              <q-toolbar-title>{{$t('Select an existing family')}}</q-toolbar-title>
            </q-toolbar>
          </q-card-section>

          <q-card-section class="items-center card-body">
            <TreeSelect class="treeSelect" :placeholder="$t('Select family')" @select="selectParentFamily" :value="selectedFamily" style="margin-top:30px" />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat :label="$t('Cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('Delete')" color="negative" @click="onDelete"/>
            <q-btn flat :label="$t('Select')" color="positive" @click="onOk"/>
          </q-card-actions>
      </q-card>

  </q-dialog>
</template>

<script>
import { can } from '@cabinetmariel/scopes';
import localization from '../../../../lib/mixins'; //eslint-disable-line
import TreeSelect from '../../../../components/treeSelect/TreeSelect.vue';

export default {
  props: ['model', 'selectedFamily'],
  mixins: [localization],
  components: {
    TreeSelect,
  },
  data() {
    return {
      localModel: this.model,
      id: null,
      locale: this.$store.state.auth.locale,
    };
  },
  mounted() {
    if (!can('segmentation', this.$store.state.auth.user.scopes)) {
      this.$q.notify({ type: 'done', message: 'Access to segmentation is READ ONLY ([segmentation.read] rights)', icon: 'warning' });
    }
  },
  watch: {
    // Watch for changes in prop to update local model
    model(newVal) {
      this.localModel = newVal;
    },
    // Also watch for changes in localModel to inform the parent
    localModel(newVal) {
      this.$emit('update:model', newVal);
    },
  },
  methods: {
    onOk(data) {
      console.log('onOk', data);

      if (this.id === null) {
        this.$q.notify({ type: 'negative', message: 'Please select a family', icon: 'warning' });
        return;
      }
      // moveParts
      this.$emit('confirm', { selectedFamily: this.id });
      this.onHide();
    },
    onCancel() {
      this.localModel = false;
    },
    onDelete() {
      this.$emit('confirm', { actionType: 'delete-family', toDelete: this.id });
      this.onHide();
    },
    onShow() {},
    onHide() {
      this.localModel = false;
    },
    moveParts(selection) {
      this.$store.dispatch('clipboard/moveParts', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      // this.$store.dispatch('clipboard/moveFamilies', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      // this.$store.dispatch('clipboard/cloneSegmentation', { from: this.$store.state.clipboard.selection.id, to: selection.id });
    },
    selectParentFamily(id) {
      this.id = id;
    },
  },
  created() { },
};
</script>

<style lang="stylus" scoped>
.dialog {
  width : 50%;
  max-width: 500px;
  min-width 300px;
}

.card-body{
 //flex-grow: 1;
}

.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*
  padding: 1rem;
  */
  margin-top: 10px;
}
.localizededition {
  margin-bottom: 25px;
}
.treeSelect {
  max-height: 300px;
}
.content {
  padding: 1rem;
}
</style>
