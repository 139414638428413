export const resetSets = (state) => {
  state.stats = {
    turnover: 0,
    purchase: 0,
    negativeprices: 0,
    purchasealerts: 0,
    variations: [0, 0, 0],
    margins: {
      sum: 0,
      sumv: 0,
      count: 0,
      countv: 0,
    },
  };
};
export const createMissingAttr = (part, list, def) => {
  Object.values(list).forEach((ruleattr) => {
    if (part[ruleattr.name] === undefined) {
      // let's put a default value
      const type = def[ruleattr.name] ? def[ruleattr.name].type : undefined;
      if (type === undefined) return;
      let value;
      switch (type) {
        case 'boolean':
        case 'rate':
          value = { type: 'boolean', v: false };
          break;
        case 'number':
        case 'amount':
          value = { type: 'number', v: { number: 0, fixed: '0' } };
          break;
        default:
          console.error('This is bad', type);
      }
      part[ruleattr.name] = value;
    }
  });
};

export const computeaverage = (references, averagereferences, part) => {
  references.filter((k) => (k !== 'price')).forEach((k) => {
    if (part[k] && ['number', 'amount', 'pricing'].includes(part[k].type)
      && part[k].v && part[k].v.number) {
      averagereferences[k].count += 1;
      averagereferences[k].value += part[k].v.number;
    }
  });
};

export const finishaverage = (references, averagereferences, exclude = 'price') => {
  references.filter((k) => (k !== exclude)).forEach((k) => {
    averagereferences[k].value = Math.round(averagereferences[k].value * 100 / averagereferences[k].count) / 100; // eslint-disable-line
  });
};

export const resetaverage = (references, averagereferences, exclude = 'price') => {
  references.filter((k) => (k !== exclude)).forEach((k) => {
    averagereferences[k] = { value: 0, count: 0 };
  });
};

export const mkemptyvalidatedprice = (state, part, one) => ({
  name: 'validatedprice',
  set: Object.values(state.sets.sets).find((s) => (s.name === 'pricing')).id,
  type: 'amount',
  v: {
    unit: (part.price && part.price[state.rule.priceset || 'DEFAULT'] && part.price[state.rule.priceset || 'DEFAULT'].length)
      ? part.price[state.rule.priceset || 'DEFAULT'][0].v.unit
      : state.rule.currency || 'CHF',
    fixed: '',
    qty: (part.price && part.price[state.rule.priceset || 'DEFAULT'] && part.price[state.rule.priceset || 'DEFAULT'].length)
      ? part.price[state.rule.priceset || 'DEFAULT'][0].v.qty
      : (one || { fixed: '1', number: 1, unit: 'PC' }),
  }, // FIXME get default currency
});

export const setOneTimeAndPriceSet = (part, priceset) => {
  const validationdate = {
    fuzzy: true,
    // id: "6e071b60-c7f1-4056-aa32-2382748365e0",
    name: 'validationdate',
    // set: "bb909172-3d35-43b5-b964-23a7ee7a5716",
    t: '(,)',
    type: 'interval',
    // updated_at: "2021-05-11T08:24:07.696Z",
    v: {},
  };
  if (part.price && part.price[priceset] && part.price[priceset][Symbol.for('actualdateindex')] > -1) {
    const idx = part.price[priceset][Symbol.for('actualdateindex')];
    const { t } = part.price[priceset][idx];
    const [from, to] = t.replace(/[[()\]]/g, '').split(',').map((b) => (b ? new Date(b) : undefined));
    validationdate.t = t;
    validationdate.v = { from, to };
  }
  part.validationdate = validationdate;
};

export const getSystemValue = ({
  attribute: { name, type }, rule, defs, settings, lookupRate,
}) => {
  const ruleCurrency = rule.currency;
  const empty = { number: 0, fixed: '0.00', unit: '' };
  const def = defs[name];

  if (!def) {
    return empty;
  }

  const defaultunit = ({ unit: ruleCurrency }).unit || '';
  const weight = (def.weight || []).find((w) => (w.type === type));

  // if is in pivot
  if ((rule?.definition?.pivot || []).findIndex((p) => (p.name === name)) > -1) {
    return empty;
  }

  if (weight?.v) {
    if (weight.v.unit === defaultunit || weight.type === 'rate') {
      return weight.v;
    }
    // we need to convert
    const number = weight.v.number * lookupRate(weight.v.unit, defaultunit, settings.ratetag.price);

    return {
      fixed: number.toFixed(2),
      number,
      unit: defaultunit,
    };
  }

  if (['pricing', 'amount'].includes(type)) {
    empty.unit = defaultunit;
  }

  return empty;
};

export default {
  setOneTimeAndPriceSet,
  mkemptyvalidatedprice,
  resetaverage,
  finishaverage,
  computeaverage,
  createMissingAttr,
  resetSets,
  getSystemValue,
};
