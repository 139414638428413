<template>
  <div v-if="$store.state.pricing.rule && $store.state.pricing.rule.definition
  && $store.state.pricing.rule.definition.autocrc" class="drawer-container" style ="width:320px;">
    <p>{{$t('The following parameters setup the auto CRC computation.')}}</p>
    <q-toggle :value="$store.state.pricing.rule.definition.autocrc.enabled" @input="enable" :label="$t('Enable Auto CRC')"/>
    <p>{{$t('If enabled this will NOT override manual CRC you could have set.')}}</p>
    <table class="crc-boundaries">
      <thead>
        <tr><th colspan="3">{{$t('Boundaries and Rates')}}</th></tr>
        <tr><th>&nbsp;</th><th>{{$t('Sales')}}</th><th colspan="">{{$t('Rate')}}</th></tr>
      </thead>
      <tbody>
        <tr>
          <th>{{$t('Min')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.boundaries[1][0]" @input="(e) => update(e, 'boundaries', 1, 0)" type="number"></td>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.boundaries[1][1]" @input="(e) => update(e, 'boundaries', 1, 1)" type="number"></td>
        </tr>
        <tr>
          <th>{{$t('Max')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.boundaries[2][0]" @input="(e) => update(e, 'boundaries', 2, 0)" type="number"></td>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.boundaries[2][1]" @input="(e) => update(e, 'boundaries', 2, 1)" type="number"></td>
        </tr>
      </tbody>
    </table>
    <table class="crc-params">
      <thead>
        <tr><th colspan="2">{{$t('CRC defaults')}}</th></tr>
      </thead>
      <tbody>
        <tr>
          <th>{{$t('Stop Value')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.stopvalue" @input="(e) => update(e, 'stopvalue')" type="number"></td>
        </tr>
        <tr><td colspan="2"><span class="fieldhint">{{$t('Threshold value on "\{\{label\}\}"', { label: localized($store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.sales].label)})}}</span></td></tr>
        <tr>
          <th>{{$t('Apply only to')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.onlyto" @input="(e) => update(e, 'onlyto')"></td>
        </tr>
        <tr><td colspan="2"><span class="fieldhint">{{$t('For "\{\{label\}\}", comma separated values allowed', { label: localized($store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.only].label) })}}</span></td></tr>
        <tr v-if="$store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.notto]">
          <th>{{$t('Don\'t Apply to')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.notto" @input="(e) => update(e, 'notto')"></td>
        </tr>
        <tr v-if="$store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.notto]">
          <td colspan="2"><span class="fieldhint">{{$t('For "\{\{label\}\}", comma separated values allowed', { label: localized($store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.notto].label) })}}</span></td>
        </tr>
        <tr v-if="$store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.maxdate]">
          <th>{{$t('Max date')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.maxdate" @input="(e) => update(e, 'maxdate')"></td>
        </tr>
        <tr v-if="$store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.maxdate]">
          <td colspan="2"><span class="fieldhint">{{$t('For "\{\{label\}\}", format (YYYY-MM-DD)', { label: localized($store.state.pricing.defs.defs2[$store.state.pricing.rule.definition.autocrc.attrs.maxdate].label) })}}</span></td>
        </tr>
        <tr>
          <th>{{$t('Max CRC value')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.maxvalue" @input="(e) => update(e, 'maxvalue')" type="number"></td>
        </tr>
        <tr>
          <th>{{$t('Default CRC value')}}</th>
          <td><input :disabled="!$store.state.pricing.rule.definition.autocrc.enabled" :value="$store.state.pricing.rule.definition.autocrc.defaultvalue" @input="(e) => update(e, 'defaultvalue')" type="number"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>{{$t('Please contact support to setup auto CRC.')}}</div>
</template>

<script>
import { localization } from '../../lib/mixins';
import { store } from '../../lib/nrstore';

export default {
  mixins: [localization],
  data() {
    return {
      timer: 0,
    };
  },
  methods: {
    enable(v) {
      this.$store.commit('pricing/updateAutoCRC', {
        value: v,
        kind: 'enabled',
      });
    },
    update(e, kind, idx1, idx2) {
      const { value } = e.target;
      let validatedValue;
      switch (kind) {
        case 'onlyto':
          validatedValue = value.split(',').map((v) => v.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase());
          break;
        case 'notto':
          validatedValue = value.split(',').map((v) => v.normalize('NFD').replace(/\p{Diacritic}/gu, '').toUpperCase());
          break;
        case 'maxdate':
          validatedValue = value;
          break;
        default:
          validatedValue = parseFloat(value);
          if (!Number.isFinite(validatedValue)) return;
      }
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout((() => {
        this.$store.commit('pricing/updateAutoCRC', {
          value: validatedValue,
          kind,
          idx1,
          idx2,
        });
      }), 500);
    },
  },
};
</script>

<style lang="stylus" scoped>
.crc-boundaries, .crc-params {
  width: 100%
  input {
    width: 100%
  }
  .ralign {
    text-align: right;
  }
}
</style>
