import layout from 'layouts/MainLayout.vue';
import header from 'pages/Header.vue';
import menu from 'pages/Menu.vue';
import main from 'pages/Index.vue';
import pricing from 'pages/pricing/Pricing.vue';
import ruledrawer from 'pages/pricing/RuleDrawer.vue';
import ruleparams from 'pages/pricing/RuleParams.vue';
import rulerefine from 'pages/pricing/RuleRefine.vue';
import rulevdiscount from 'pages/pricing/RuleVdiscount.vue';
import rulecrc from 'pages/pricing/RuleCRC.vue';
import rulehistory from 'pages/pricing/RuleHistory.vue';
import ruleattribute from 'pages/pricing/RuleAttribute.vue';
import addattr from 'pages/pricing/AddRuleAttr.vue';
import weighttype from 'pages/pricing/AddRuleAttrWeight.vue';
import newattr from 'pages/pricing/NewAttr.vue';
import selectattr from 'pages/pricing/SelectAttr.vue';
import selectblock from 'pages/pricing/SelectBlock.vue';
import familyselection from 'pages/FamilySelection.vue';
import tags from 'pages/settings/tags/Tags2.vue';
import users from 'pages/settings/users/users.vue';
import taux from 'pages/settings/taux/taux.vue';
// import tagDetails from 'components/drawer/tags/tagsDrawer.vue';
import catalog from 'pages/products/products.vue';
import ImportExport from 'pages/importExport/ImportExport.vue';
import Import from 'pages/importExport/import.vue';
import Upload from 'pages/importExport/upload.vue';
import ListImports from 'pages/importExport/ListImports.vue';
import Product from 'pages/productDetails/productDetails.vue';
import usersDrawer from 'components/drawer/users/usersDrawer.vue';
import Error from 'pages/Error.vue';
import Login from 'pages/Login.vue';
import Attributes from 'pages/settings/attributes/Attributes.vue';
import attributesDetails from 'components/drawer/attributes/attributesDrawer.vue';
import Segmentation from 'pages/settings/segmentation/Segmentation.vue';
import segmentationDetails from 'components/drawer/segmentation/segmentationDrawer.vue';

import DgPricingMethod from 'pages/settings/decisionGrid/PricingMethod.vue';
import Notifications from 'pages/notifications/index.vue';
import rulecomment from 'pages/pricing/CommentDrawer.vue';
import rulesave from 'pages/pricing/SaveDrawer.vue';
import searchref from 'pages/pricing/SearchRefDrawer.vue';
import setConsistency from 'src/pages/pricing/SetConsistencyDrawer.vue';
import rulepreferences from 'pages/pricing/RuleSettings.vue';
import loadrule from 'pages/pricing/LoadDrawer.vue';
import loadsearch from 'pages/pricing/LoadSearchDrawer.vue';
import saveprice from 'pages/pricing/SavePrice.vue';
import { save } from 'src/store/pricing/actions';
import resegment from 'pages/pricing/Resegment';
import toto from 'pages/importExport/toto';
import comment from 'pages/pricing/Comment';
import attributehistory from 'pages/pricing/AttributeHistory';
import exportsrule from 'pages/pricing/ExportsRule';
import importtagconf from 'pages/importExport/pricelists';
import ProcessesVue from 'pages/Processes.vue';
import RuleProgressive from 'pages/pricing/RuleProgressive.vue';
import CostPlusPath from 'pages/pricing/CostPlusPath.vue';

const routes = [{
  path: '/error',
  name: 'error',
  component: Error,
},
{
  path: '/login',
  name: 'login',
  component: Login,
},
{
  path: '/',
  component: layout,
  redirect: '/index',
  children: [{
    path: 'index',
    components: {
      header,
      main,
      menu,
    },
  },
  {
    path: 'settings/notifications',
    name: 'notifications',
    components: {
      header,
      menu,
      main: Notifications,
    },
  },
  {
    path: 'settings/processes',
    name: 'processes',
    components: {
      header,
      menu,
      main: ProcessesVue,
    },
  },
  {
    path: 'products/:id',
    name: 'product',
    components: {
      header,
      menu,
      main: Product,
    },
  },
  {
    path: 'products/',
    name: 'products',
    components: {
      header,
      menu,
      main: catalog,
    },
  },
  {
    path: 'importtagconf',
    components: {
      header,
      menu,
      main: importtagconf,
    },
    name: 'importpricelist',
  },
  {
    path: 'exportparts/',
    name: 'toto',
    components: {
      header,
      menu,
      main: toto,
    },
  },
  {
    path: 'settings/tags/:path*',
    name: 'tags',
    components: {
      header,
      menu,
      main: tags,
    },
    /* children: [{
      path: 'drawer/:action',
      component: tagDetails,
      name: 'tagDetails',
    }], */
  },
  {
    path: 'settings/attributes/*',
    name: 'attributes',
    components: {
      header,
      menu,
      main: Attributes,
    },
    children: [{
      path: 'drawer/:action/:column?',
      component: attributesDetails,
      name: 'attributesDetails',
    }],
  },
  {
    path: 'settings/segmentation/*',
    name: 'segmentation',
    components: {
      header,
      menu,
      main: Segmentation,
    },
    children: [
      {
        path: 'drawer/:action/:column?',
        component: segmentationDetails,
        name: 'segmentationDetails',
      },
    ],
  },
  {
    path: 'settings/users',
    name: 'users',
    components: {
      header,
      menu,
      main: users,
    },
    children: [{
      path: 'details/:id',
      component: usersDrawer,
      name: 'usersDrawer',
    }],
  },
  {
    path: 'settings/taux',
    name: 'taux',
    components: {
      header,
      menu,
      main: taux,
    },
    /* children: [{
      path: 'details/:id',
      component: usersDrawer,
      name: 'usersDrawer',
    }], */
  },
  {
    path: 'settings/decisiongrid/:name',
    name: 'pricingMethod',
    components: {
      header,
      menu,
      main: DgPricingMethod,
    },
  },
  {
    path: 'pricing',
    components: {
      header,
      menu,
      main: familyselection,
    },
  },
  {
    path: 'pricing/:id',
    name: 'pricing',
    alias: 'one/:id',
    components: {
      header,
      menu,
      main: pricing,
    },
    children: [{
      path: 'rule',
      component: ruledrawer,
      children: [{
        path: 'params',
        name: 'ruleparams',
        component: ruleparams,
      },
      {
        path: 'refine',
        component: rulerefine,
        name: 'refine',
      },
      {
        path: 'vdiscount',
        name: 'vdiscount',
        component: rulevdiscount,
      },
      {
        path: 'crcparam',
        name: 'crcparam',
        component: rulecrc,
      },
      ],
    },
    {
      path: 'addattr',
      component: addattr,
      name: 'addselect',
    },
    {
      path: 'addattr/:name',
      name: 'addattr',
      component: weighttype,
    },
    {
      path: 'newattr',
      name: 'newattr',
      component: newattr,
    },
    {
      path: 'selectattr',
      name: 'selectattr',
      component: selectattr,
    },
    {
      path: 'selectblock/:attr/:fid',
      name: 'selectblock',
      component: selectblock,
    },
    {
      path: 'resegment/:pid',
      name: 'resegment',
      component: resegment,
    },
    {
      path: 'comment/:pid',
      name: 'comment',
      component: comment,
    },
    {
      path: 'costpluspath',
      name: 'costpluspath',
      component: CostPlusPath,
    },
    {
      path: 'attributehistory/:pid/:attr',
      name: 'attributehistory',
      component: attributehistory,
    },
    {
      path: 'comment',
      name: 'rulecomment',
      component: rulecomment,
    },
    {
      path: 'save',
      name: 'rulesave',
      component: rulesave,
    },
    {
      path: 'searchref',
      name: 'searchref',
      component: searchref,
    },
    {
      path: 'setConsistency',
      name: 'setConsistency',
      component: setConsistency,
    },
    {
      path: 'saveprices',
      name: 'rulesaveprices',
      component: saveprice,
    },
    {
      path: 'load',
      name: 'loadrule',
      component: loadrule,
    },
    {
      path: 'loadsearch',
      name: 'loadsearch',
      component: loadsearch,
    },
    {
      path: 'searchresult/:ifid',
      name: 'searchresult',
      component: loadrule,
    },
    {
      path: 'exports',
      name: 'exportsrule',
      component: exportsrule,
    },
    {
      path: 'preferences',
      name: 'rulepreferences',
      component: rulepreferences,
    },
    {
      path: 'charts',
      name: 'charts',
      component: rulehistory,
    },
    {
      path: 'displaypreferences',
      name: 'displaypreferences',
      component: ruleattribute,
    },
    {
      path: 'progressive',
      name: 'progressive',
      component: RuleProgressive,
    },
    ],
  },
  {
    path: 'importexport',
    components: {
      header,
      menu,
      main: ImportExport,
    },
    children: [{
      path: '',
      component: ListImports,
      name: 'listImport',
    },
    {
      path: 'imports',
      component: Import,
      name: 'imports',
    },
    {
      path: 'preupload/:id',
      component: Upload,
      name: 'preupload',
    },
    {
      path: 'upload/:id',
      component: Upload,
      name: 'upload',
    },
    ],
  },
  ],
},
];

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue'),
  });
}

export default routes;
