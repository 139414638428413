import { render, staticRenderFns } from "./CreateAttrDialog.vue?vue&type=template&id=0040378f&scoped=true&"
import script from "./CreateAttrDialog.vue?vue&type=script&lang=js&"
export * from "./CreateAttrDialog.vue?vue&type=script&lang=js&"
import style0 from "./CreateAttrDialog.vue?vue&type=style&index=0&id=0040378f&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0040378f",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard});
