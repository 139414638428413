<!-- eslint-disable no-nested-ternary -->
<template>
  <div class="CostPlusControls" v-if="currentNode">
    <label style="width:100%; text-align: center;"><b>{{ $t('Edit Nodes') }}</b></label>
    <p style="margin:0; margin-top:5px;">Move a node</p>
    <div class="topBar" >
      <q-btn class="btn" @click="moveUp()" :disabled="currentNode.decision" color="primary" icon="arrow_upward" dense data-tooltip="Move the selected node up inside the branch"></q-btn>
      <q-btn class="btn" @click="moveDown()" :disabled="currentNode.decision" color="primary" icon="arrow_downward" dense data-tooltip="Move the selected node down inside the branch"></q-btn>
    </div>
    <p style="margin:0; margin-top:-5px">Edit a node</p>
    <div class="topBar" >
      <q-btn class="btn" @click="rm()"  color="primary" icon="delete" dense data-tooltip="Delete the selected node"></q-btn>
      <q-btn class="btn" @click="addChild()"  color="primary" icon="arrow_right" dense data-tooltip="Add a child node to the selected node"></q-btn>
      <q-btn class="btn" @click="addSiblingUp()" :disabled="currentNode.decision" color="primary" icon="arrow_upward" dense data-tooltip="Add a sibling node before the selected node"></q-btn>
      <q-btn class="btn" @click="addSiblingDown()" :disabled="currentNode.decision" color="primary" icon="arrow_downward" dense data-tooltip="Add a sibling node after the selected node"></q-btn>
    </div>

    <div class="line" style="margin-bottom:5px;">
      <select :value="currentNode.condition ? 'attribute' : 'decision'" @change="changeType">
        <option value="attribute">Attribute</option>
        <option value="decision">Decision</option>
      </select>
    </div>
    <!-- {{ currentNode.condition }} -->
    <div class="line">
      <select v-if="currentNode.condition" v-model="currentNode.condition.attribute" @change="changeAttr">
        <option v-for="(attr, iattr) in attributsList" :key="iattr" :value="attr.value">{{ attr.text }}</option>
      </select>
    </div>

    <div class="cont">

      <!-- <div class="arrow" v-if="currentNode.condition">
        <q-btn class="btn" @click="moveUp()" color="primary" icon="arrow_upward" dense></q-btn>
        <q-btn class="btn" @click="moveDown()" color="primary" icon="arrow_downward" dense></q-btn>
      </div> -->

      <div v-if="currentNode.condition && currentNode.condition.op && isOpString(currentNode.condition.op, currentNode.condition.attribute)" class="cond">
        <div class="line">
          <select  v-model="currentNode.condition.op" @change="changeOp($event, currentNode)">
            <option v-for="(ops, iops) in opsString" :key="iops" :value="ops.value">{{ ops.text }}</option>
          </select>
        </div>
        <div class="line">
          <select v-if="currentNode.condition.op != 'true'" :value="currentNode.condition.args[0].split(',').length > 1 ? 'multiple' : 'one'">
            <option v="one">one</option>
            <option v="multiple">multiple</option>
          </select>
        </div>
        <div class="line">
          <textarea rows="3" type="text" v-if="currentNode.condition.op != 'true'" v-model="currentNode.condition.args[0]" />
        </div>
      </div>

      <div v-else-if="currentNode.condition && currentNode.condition.op && isOpNumber(currentNode.condition.op, currentNode.condition.attribute)" class="cond">
        <div class="line">
          <select v-model="currentNode.condition.op" @change="changeOp($event, currentNode)">
            <option v-for="(ops, iops) in opsNumber" :key="iops" :value="ops.value">{{ ops.text }}</option>
          </select>
        </div>
        <div class="line">
          <!--{{ currentNode.condition.args  }} -->
        </div>
          <div class="line">
          <input v-model="currentNode.condition.args[0].number" style="width:calc(100% - 70px);"/>
          <select v-model="currentNode.condition.args[0].unit" v-if="this.unitsAvailable.length > 0" style="margin-left:5px; width:70px">
            <option v-for ="(unit, iunit) in unitsAvailable" :key="iunit" :value="unit.value">{{ unit.text }}</option>
          </select>
        </div>
        <div class="line" v-if="currentNode.condition.op ==='bt' && currentNode.condition.args.length > 1">
          <input v-model="currentNode.condition.args[1].number" style="width:calc(100% - 70px);" />
          <select  v-model="currentNode.condition.args[1].unit" v-if="this.unitsAvailable.length > 0" style="margin-left:5px; width:70px ">
            <option v-for ="(unit, iunit) in unitsAvailable" :key="iunit" :value="unit.value" >{{ unit.text }}</option>
          </select>
        </div>
      </div>

      <div v-else-if="currentNode.decision !== null && currentNode.decision !== undefined" >
        <div class="line">
          <input type="radio" :checked="currentNode.decision !== 'auto'" @click="currentNode.decision = 1;" style="margin-right:5px;"/>
          <input type="number" min="0" max="10" v-model="currentNode.decision" :disabled="currentNode.decision === 'auto'" />
        </div>
        <div class="line">
          <input type="radio" :checked="currentNode.decision === 'auto'" @click="currentNode.decision = 'auto';" style="margin-right:5px;"/>
          <label>auto</label>
        </div>
      </div>

    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';

const { pricinglib } = pricingUtilities;
const { feexs } = pricinglib;
import { v4 as uuidv4 } from 'uuid';
import { localization } from '../../../lib/mixins';

export default {
  name: 'CostPlusControls',
  mixins: [localization],
  data() {
    return {
      currentNode: null,
      opsString: [
        /*
        { text: 'Start with', value: 'sw' },
        { text: 'End with', value: 'ew' },
        { text: 'Contains', value: 'co' },
        { text: 'Equal', value: 'eq' },
        { text: 'Not equal', value: 'ne' },
        { text: 'True', value: 'true' },
        { text: 'False', value: 'false' },
        */
      ],
      opsNumber: [
        /*
        { text: 'Equal', value: 'eq' },
        { text: 'Not equal', value: 'ne' },
        { text: 'Greater than', value: 'gt' },
        { text: 'Less than', value: 'lt' },
        { text: 'Greater than or equal', value: 'ge' },
        { text: 'Less than or equal', value: 'le' },
        { text: 'Between', value: 'bt' },
        { text: 'Not between', value: 'nb' },
        { text: 'In', value: 'in' },
        { text: 'Not in', value: 'ni' },
        { text: 'Is null', value: 'is' },
        { text: 'Is not null', value: 'ns' },
        */
      ],
      unitsAvailable: [
        /*
        { text: 'EUR', value: 'EUR' },
        { text: 'CHF', value: 'CHF' },
         */
      ],
    };
  },
  props: ['value', 'selectedNodeId', 'attributsList', 'currency'],
  computed: {
    v: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('update:v', v);
      },
    },
  },
  watch: {
    selectedNodeId(newVal) {
      console.log('selectedNodeId ', newVal);
      const pathToNode = this.findPathToNode(this.value, newVal);
      if (pathToNode.length === 0) {
        this.currentNode = null;
      } else {
        let parentNode = this.value;
        // Iterate to the parent of the target node
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < pathToNode.length - 1; i++) {
          parentNode = parentNode[pathToNode[i]].children;
        }
        // Access the target node directly
        this.currentNode = parentNode[pathToNode[pathToNode.length - 1]];
      }
      console.log('Found node: ', this.currentNode);
    },
  },
  mounted() {
    console.log('feexs');
    console.log(feexs);
    /*
    feexs
    bt    :
    {arity: 2, label: {…}, name: 'bt', type:'number', test: ƒ}
    gt    :
    {arity: 1, label: {…}, name: 'gt', type:'number' test: ƒ}
    lt    :
    {arity: 1, label: {…}, name: 'lt', type:'number' test: ƒ}
    sw    :
    {arity: 1, name: 'sw', label: {…}, type:'string' test: ƒ}
    */

    // feed the opsNumber with feexs object
    this.opsNumber = [];
    this.opsString = [];
    Object.keys(feexs).forEach((key) => {
      if (feexs[key].type === 'number') {
        this.opsNumber.push({
          text: this.localized(feexs[key].label),
          value: feexs[key].name,
          arity: feexs[key].arity,
        });
      } else if (feexs[key].type === 'string') {
        this.opsString.push({
          text: this.localized(feexs[key].label),
          value: feexs[key].name,
          arity: feexs[key].arity,
        });
      }
    });
    console.log('opsNumber', this.opsNumber);
    console.log('opsString', this.opsString);
  },
  methods: {

    findPathToNode(tree, nodeId, path = []) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        const newPath = path.concat(i);
        if (node.id === nodeId) {
          return newPath;
        }
        if (node.children) {
          const childPath = this.findPathToNode(node.children, nodeId, newPath);
          if (childPath.length) {
            return childPath;
          }
        }
      }
      return [];
    },

    changeType(event) {
      const selectedType = event.target.value;
      if (selectedType === 'attribute') {
        // Change the node to an attribute type
        this.$set(this.currentNode, 'condition', {
          attribute: '',
          op: '',
          args: [],
        });
        // Delete the decision property if it exists
        this.$delete(this.currentNode, 'decision');
        // Ensure children property exists
        if (!this.currentNode.children) {
          this.$set(this.currentNode, 'children', []);
        }
      } else if (selectedType === 'decision') {
        // Change the node to a decision type
        this.$set(this.currentNode, 'decision', 'auto');
        // Delete the condition property if it exists
        this.$delete(this.currentNode, 'condition');
        // Delete the children property if it exists
        this.$delete(this.currentNode, 'children');
      }
    },

    changeAttr(event) {
      // find type of event.target.value in attributsList
      const index = this.attributsList.findIndex((i) => i.value === event.target.value);

      console.log('changeAttr');
      console.log(this.attributsList[index]);

      this.unitsAvailable = [];
      if (this.attributsList[index].type === 'string' || this.attributsList[index].type === 'localizedstring') {
        this.currentNode.condition.op = 'sw';
        this.currentNode.condition.args = [''];
      } else {
        this.currentNode.condition.op = 'lt';

        if (this.attributsList[index].type === 'pricing') {
          this.unitsAvailable = this.currency;
          this.currentNode.condition.args = [{
            number: 0,
            unit: this.unitsAvailable[0].value,
          }];
        } else if (this.attributsList[index].type === 'number') {
          this.currentNode.condition.args = [{
            number: 0,
          }];

          // if arity is 2, add a second arg
          if (this.opsNumber.find((i) => i.value === this.currentNode.condition.op).arity === 2) {
            this.currentNode.condition.args.push({
              number: 0,
            });
          }
        }
      }
    },
    changeOp(event, node) {
      const selectedOp = event.target.value;
      console.log('selectedOp', selectedOp);

      // find operatore in opsNumber or opsString
      const index = this.opsNumber.findIndex((i) => i.value === selectedOp);
      if (index > -1) {
        // if arity is 2, add a second arg
        if (this.opsNumber[index].arity === 2) {
          this.currentNode.condition.args.push({
            number: 0,
            unit: this.unitsAvailable[0].value,
          });
        } else {
          this.currentNode.condition.args = [{
            number: 0,
            unit: this.unitsAvailable[0].value,
          }];
        }
        return;
      }

      const index2 = this.opsString.findIndex((i) => i.value === selectedOp);
      if (index2 > -1) {
        // if arity is 2, add a second arg
        if (this.opsString[index2].arity === 2) {
          this.currentNode.condition.args.push('');
        } else {
          this.currentNode.condition.args = [''];
        }
      }
    },
    isOpNumber(op, attr) {
      if (!op) return false;
      const ii = this.attributsList.findIndex((i) => i.value === attr);

      if (ii < 0
      || (this.attributsList[ii].type === 'string'
      || this.attributsList[ii].type === 'localizedstring'
      || this.attributsList[ii].type === 'collection')
      ) {
        return false;
      }

      // console.log('isOpNumber');
      // console.log('attr', attr);
      // console.log('type', this.attributsList[ii].type);
      // console.log('check op OK', op);
      // console.log('opsNumber', this.opsNumber);
      const index = this.opsNumber.findIndex((i) => i.value === op);
      // console.log('index', index);
      return index > -1;
    },
    isOpString(op, attr) {
      if (!op) return false;
      const ii = this.attributsList.findIndex((i) => i.value === attr);

      if (ii < 0
      || !(this.attributsList[ii].type === 'string'
      || this.attributsList[ii].type === 'localizedstring'
      || this.attributsList[ii].type === 'collection')
      ) {
        return false;
      }

      // console.log('isOpString');
      // console.log('attr', attr);
      // console.log('type', this.attributsList[ii].type);
      // console.log('check op OK', op);
      const index = this.opsString.findIndex((i) => i.value === op);
      // console.log('index', index);
      return index > -1;
    },

    /* CONTROL BUTTONS */
    moveNode(direction) {
      const pathToNode = this.findPathToNode(this.value, this.selectedNodeId);
      if (pathToNode.length === 0) return;

      let currentArray = this.value;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < pathToNode.length - 1; i++) {
        currentArray = currentArray[pathToNode[i]].children;
      }

      const nodeIndex = pathToNode[pathToNode.length - 1];
      const targetIndex = nodeIndex + direction;

      // Check bounds
      if (targetIndex < 0 || targetIndex >= currentArray.length) return;

      // Swap nodes
      const tmp = currentArray[targetIndex];
      this.$set(currentArray, targetIndex, currentArray[nodeIndex]);
      this.$set(currentArray, nodeIndex, tmp);
    },
    moveUp() {
      this.moveNode(-1);
    },
    moveDown() {
      this.moveNode(1);
    },

    newNode() {
      return {
        id: uuidv4(),
        decision: 1,
      };
    },
    addChild() {
      const pathToNode = this.findPathToNode(this.value, this.selectedNodeId);
      if (pathToNode.length === 0) return;

      let parentNode = this.value;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < pathToNode.length - 1; i++) {
        parentNode = parentNode[pathToNode[i]].children;
      }
      const node = parentNode[pathToNode[pathToNode.length - 1]];

      if (!node.children) {
        this.$set(node, 'children', []);
      }

      node.children.push(this.newNode());
    },

    addSiblingNode(position) {
      const pathToNode = this.findPathToNode(this.value, this.selectedNodeId);
      if (pathToNode.length === 0) return;
      let parentArray = this.value;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < pathToNode.length - 1; i++) {
        parentArray = parentArray[pathToNode[i]].children;
      }
      const nodeIndex = pathToNode[pathToNode.length - 1];
      parentArray.splice(nodeIndex + position, 0, this.newNode()); // Insert at the specified position
    },
    addSiblingUp() {
      this.addSiblingNode(0);
    },
    addSiblingDown() {
      this.addSiblingNode(1);
    },

    rm() {
      const pathToNode = this.findPathToNode(this.value, this.selectedNodeId);
      if (pathToNode.length === 0) return;
      if (pathToNode.length === 1) {
        this.value.splice(pathToNode[0], 1);
      } else {
        let currentArray = this.value;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < pathToNode.length - 1; i++) {
          currentArray = currentArray[pathToNode[i]].children;
        }

        console.log('currentArray[pathToNode[pathToNode.length - 1]] ', currentArray[pathToNode[pathToNode.length - 1]]);
        currentArray.splice(pathToNode[pathToNode.length - 1], 1);
        // delete currentArray[pathToNode[pathToNode.length - 1]].children;
        // currentArray[pathToNode[pathToNode.length - 1]] = this.newNode();

        // make the previous node a decision
        // if (currentArray.length > 0) {
        //   currentArray[currentArray.length - 1] = this.newNode();
        // }
      }
    },
  },
};
</script>

<style scoped>
.CostPlusControls {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width:100%;
  height: 100%;
  border-top: 1px solid rgba(155, 155, 155, 0.678);
  padding-top: 15px;
}

.name {
  margin: 0;
  margin-bottom: 5px;
}

.arrow{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  height: calc(100% - 5px);
}

.cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.cond {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.line {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  vertical-align: middle;
  margin-bottom: 5px;
  text-align: left;
  /*border: 1px solid red;*/
  width: 100%;
  /*width: 220px;*/
}

label {
  margin-left: 3px;
}

select{
  width: 100%;
}

.btnCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 0px;
  height: 100%;
}

.btn {
  margin-bottom: 5px;
}

select {
  font-size: 15px;
  padding: 3px;
  border: 1px solid grey;
  border-radius: 3px;
  /* width: 100%;*/
  width: 100%;
}

textarea {
  width: 100%;
  height: 300px;
}

input[type=number],
input[type=text] {
  font-size: 15px;
  padding: 1.5px;
  border: 1px solid grey;
  border-radius: 3px;
  width: 100%;
}

.topBar{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.btn {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.btn::before,
.btn::after {
  content: '';
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.btn::before {
  content: attr(data-tooltip);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  padding: 7px;
  color: #fff;
  background: #333;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
}

.btn::after {
  content: '';
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  max-width: 200px;
  white-space: pre-wrap;
}

.btn:hover::before,
.btn:hover::after {
  visibility: visible;
  opacity: 1;
}

.spacer{
  flex-grow: 1;
}
</style>
