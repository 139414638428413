import { render, staticRenderFns } from "./AddRuleAttrWeight.vue?vue&type=template&id=a8bcbcb6&"
import script from "./AddRuleAttrWeight.vue?vue&type=script&lang=js&"
export * from "./AddRuleAttrWeight.vue?vue&type=script&lang=js&"
import style0 from "./AddRuleAttrWeight.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QList from 'quasar/src/components/item/QList.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBtn,QToolbarTitle,QItem,QItemSection,QIcon,QItemLabel,QChip,QList,QRadio,QCheckbox,QBanner,QLayout});
