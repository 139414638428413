<template>
<div class="segmentationcard">
  <div class="tag">{{$t('Segmentation Event')}}</div>
  <div class="helper">{{$t('Previous family:')}}</div><ul class="family">
    <li v-for="f in families" :key="f.id">{{f.label}}</li></ul>
</div>
</template>
<script>
import localization from '../../../lib/mixins'; // eslint-disable-line

export default {
  mixins: [localization],
  props: ['data', 'locale'],
  data() {
    return {
      families: [],
    };
  },
  mounted() {
    console.log('mounted', this.data);
    const { oldkeys: { keyvalues: [, family] } } = this.data;
    this.$axios.get(`/families/${family}/path`).then((response) => {
      response.data.data.forEach((f) => {
        f.label = this.localized(f.label);
      });
      this.families = response.data.data;
    });
  },
};
</script>
<style lang="stylus" scoped>
.segmentationcard {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  align-items: flex-start;
  padding: 4px
  .tag {
    background-color: #fe452D;
    border-radius: 10px;
    color: white;
    text-align: center;
    padding: 2px 10px;
    font-variant: small-caps;
    font-weight: bold;
  }
  .family {
    font-size: 1rem;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li:not(:last-child)::after {
      display: inline-block;
      margin: 0 .25rem;
      content: "→";
    }
  }
  .helper {
    font-size: 1rem;
    color: #aaa;
    }
}

</style>
