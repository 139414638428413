<!-- eslint-disable no-nested-ternary -->
<template>
  <q-dialog v-model="showMe" persistent transition-show="scale" transition-hide="scale">
    <q-card class="modaleCard">

      <q-card-section>
        <div class="text-h6">{{ label }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none section1" style="margin-bottom:10px">
        {{ $t('Add, edit, delete or move condition to change the cost plus calculation') }}
      </q-card-section>

      <div class="costPlus">
       <div class="costPlusScroll">
          <SvgTree :data="costPlus" @nodeSelected="handleNodeSelected" :attributsList="attributsList" @hasError="handleTreeError" />
         </div>
        <div class="edit">
          <div class="loadConfig">
            <label><b>{{ $t('Load a configuration') }}</b></label>
            <q-select
              v-model="costPlusSelected"
              :options="costPlusLibrary"
              :option-label="item => localized(item.label)"
              style="width:100%; margin-bottom:15px"
            />
            <q-btn @click="loadConfig" style="width:100%">Load config</q-btn>
          </div>
          <CostPlusControls v-model="costPlus" :selectedNodeId="selectedNodeId" :attributsList="attributsList" :currency="currency"/>
        </div>
      </div>

      <q-card-actions align="right" class="bg-white text-teal cardAction">
        <q-btn :label="$t('Abord and quit')" flat @click="abord" />
        <q-btn :label="$t('Save and quit')" flat @click="validate" :disable="!admin" />
      </q-card-actions>

    </q-card>
  </q-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { can } from '@cabinetmariel/scopes';
import { localization } from '../../../lib/mixins';

import SvgTree from './SvgTree';
import CostPlusControls from './CostPlusControls.vue';

export default {
  props: ['show', 'value', 'label'],
  emits: ['closemodale', 'ready', 'input'],
  mixins: [localization],
  data() {
    return {
      costPlus: [],
      attributsList: [],
      currency: [],
      groupeSelect: [],
      groupDec: 1,
      groupAuto: true,
      selectedNodeId: [],
      // tree: null,
      errorInTree: false,
      // costPlusLibrary
      costPlusLibrary: [],
      costPlusSelected: null,
    };
  },
  components: {
    SvgTree,
    CostPlusControls,
  },
  computed: {
    showMe: {
      get() { return this.show; },
      set(value) { this.$emit('closemodale'); },
    },
    admin() {
      return can('admin', this.$store.state.auth.user.scopes);
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.load();
      },
    },
    // value() {
    //   this.load();
    // },
  },
  mounted() {
    /*
    // Post a new tree
    const newTree = {
      label: { 'en-US': 'New tree 2' },
      data: {
        condition: {
          attribute: 'producthierarchy',
          op: 'sw',
          args: [''],
        },
        children: [
          {
            decision: '1',
          },
        ],
      },
    };
    this.$axios.post('/costpluslibrary', newTree).then((res) => {
      console.log(res.data.data);
    });
    */

    this.$axios.get('/costpluslibrary').then((res) => {
      console.log('/costpluslibrary', res.data.data);
      this.costPlusLibrary = res.data.data;
      if (this.costPlusLibrary.length > 0) {
        [this.costPlusSelected] = this.costPlusLibrary;
      }
      this.$forceUpdate();
    });
  },
  methods: {
    loadConfig() {
      console.log('loadConfig', this.costPlusSelected);
      const tmpCostPlus = this.costPlusSelected.data;
      console.log('tmpCostPlus', tmpCostPlus);

      // add ids to the tree
      const addId = (node) => {
        node.id = uuidv4();
        if (node.children) {
          node.children.forEach((child) => {
            addId(child);
          });
        }
      };
      tmpCostPlus.forEach((child) => {
        addId(child);
      });
      this.costPlus = tmpCostPlus;
      console.log('after ids', this.costPlus);
    },
    handleTreeError(value) {
      console.log('error in tree');
      this.errorInTree = value;
    },
    handleNodeSelected(nodeData) {
      this.selectedNodeId = nodeData.data.id;
    },
    load() {
      if (!this.show) return;

      // get the value from the parent
      let tmpCostPlus = this.value;
      console.log('tmpCostPlus', tmpCostPlus);
      if (!Array.isArray(tmpCostPlus)) {
        console.log('tmpCostPlus is not an array:', tmpCostPlus);
        tmpCostPlus = [{
          condition: {
            attribute: 'producthierarchy',
            op: 'sw',
            args: [''],
          },
          children: [
            {
              decision: 1,
            },
          ],
        }];
      }

      // add ids to the tree
      const addId = (node) => {
        node.id = uuidv4();
        if (node.children) {
          node.children.forEach((child) => {
            addId(child);
          });
        }
      };
      tmpCostPlus.forEach((child) => {
        addId(child);
      });
      this.costPlus = tmpCostPlus;
      const { defs2 } = this.$store.state.pricing.defs;

      this.attributsList = Object.values(defs2).filter((a) => !a.name.endsWith('$')).map((v) => ({
        text: this.localized(v.label),
        value: v.name,
        type: v.type,
      })).sort((a, b) => a.text.localeCompare(b.text));

      console.log('settings.currency', this.$store.state.general.settings.currency);
      this.currency = [
        { text: 'EUR', value: 'EUR' },
        { text: 'CHF', value: 'CHF' },
      ];
      const storeCurrency = this.$store.state.general.settings.currency;
      if (storeCurrency && !this.currency.some((currency) => currency.value === storeCurrency)) {
        this.currency.push({
          text: storeCurrency,
          value: storeCurrency,
        });
      }

      // tests data
      // tmpCostPlus = JSON.parse(JSON.stringify(this.costPlus));
      console.log('tmpCostPlus', this.costPlus);
      // this.costPlus = this.tree;
      this.$emit('ready');
    },
    validate() {
      if (this.errorInTree) {
        this.$q.notify({ type: 'negative', message: 'There is an error in the tree, please correct it before saving', icon: 'done' });
        return;
      }
      console.log('before remove ids', this.costPlus);

      // remove all the .id from the tree before saving
      const removeId = (node) => {
        delete node.id;
        if (node.children) {
          node.children.forEach((child) => {
            // if (child.decision) {
            //   console.log('node3', child);
            //   child.decision = parseFloat(child.decision);
            // }
            removeId(child);
          });
        }
      };
      this.costPlus.forEach((child) => {
        removeId(child);
      });

      console.log('after remove ids', this.costPlus);

      // this.$store.commit('pricing/setCostPlus', this.costPlus);
      // this.$q.notify({ type: 'positive', message: 'Your modifications have been saved', icon: 'done' });
      this.$emit('input', this.costPlus);
      // this.$emit('closemodale');
    },
    abord() {
      this.$q.notify({ type: 'warning', message: 'Your modifications have been discard', icon: 'done' });
      this.$emit('closemodale');
    },
  },
};
</script>

<style lang="stylus" scoped>

.modaleCard {
  width:100%;
  height: 100%;
  max-width: 1200px;
  max-height: 80vh;
  overflow: hidden;
}

.cardAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid grey;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.costPlus {
  margin: auto;
  box-sizing: border-box;
  padding: 0px 10px;
  margin-top: -25px;
  height: calc(100% - 140px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  //border: 1px solid red;

  .costPlusScroll {
    box-sizing: border-box;
    height: 100%;
    width: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: auto;
    //border: 1px solid blue;
  }
  .edit{
    height: calc(100% - 30px);
    width: 250px;
    margin-left: 5px;
    border-left: 1px solid rgba(155, 155, 155, 0.678);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .loadConfig{
    display:flex;
    flex-direction:column;
    align-items: center;
    width:90%;
    margin:auto;
   // margin-top:15px;
    margin-bottom:15px;
  }
}

</style>
