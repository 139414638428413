<template>
  <q-dialog
    v-model="localModel"
    prevent-close
    @ok="onOk"
    @cancel="onCancel"
    @show="onShow"
    @hide="onHide"
  >
      <q-card class="dialog">

          <q-card-section class="row items-center">
            <q-toolbar class="bg-primary shadow2 text-white">
              <q-toolbar-title>{{$t('Confirm action')}}</q-toolbar-title>
            </q-toolbar>
          </q-card-section>

          <q-card-section class="items-center card-body">
            {{ $t(text) }}
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat :label="$t('Cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('Confirm')" color="positive" @click="onOk"/>
          </q-card-actions>

      </q-card>

  </q-dialog>
</template>

<script>
import { can } from '@cabinetmariel/scopes';
import localization from '../../../../lib/mixins'; //eslint-disable-line

export default {
  props: ['model', 'text'],
  mixins: [localization],
  data() {
    return {
      localModel: this.model,
      id: null,
      locale: this.$store.state.auth.locale,
    };
  },
  mounted() {
    if (!can('segmentation', this.$store.state.auth.user.scopes)) {
      this.$q.notify({ type: 'done', message: 'Access to segmentation is READ ONLY ([segmentation.read] rights)', icon: 'warning' });
    }
  },
  watch: {
    model(newVal) {
      this.localModel = newVal;
    },
    localModel(newVal) {
      this.$emit('update:model', newVal);
    },
  },
  methods: {
    onOk(data) {
      console.log('onOk', data);
      this.$emit('confirm', data);
    },
    onCancel() {
      this.localModel = false;
    },
    onShow() {},
    onHide() {
      this.localModel = false;
    },
  },
  created() { },
};
</script>

<style lang="stylus" scoped>
.dialog {
  width : 50%;
  max-width: 500px;
  min-width 300px;
}

.card-body{
 //flex-grow: 1;
}

.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*
  padding: 1rem;
  */
  margin-top: 10px;
}
.localizededition {
  margin-bottom: 25px;
}
.treeSelect {
  max-height: 300px;
}
.content {
  padding: 1rem;
}
</style>
