import { Notify } from 'quasar';

const equal = require('fast-deep-equal');

const deepClone = (value) => JSON.parse(JSON.stringify(value));

function strcmp(str1, str2) {
  return str1 == str2 ? 0 : str1 > str2 ? 1 : -1; //eslint-disable-line
}

export const resetAllSteps = (state) => {
  state.currentStep = 1;
  state.step1.file = {
    uploaded: null,
    parsed: null,
    table: null,
    expandedCollumns: [],
    parsedLen: 0,
  };

  state.step2.form.encoding.value = 'utf8';
  state.step2.form.separator.value = '';
  state.step2.form.checkbox.value = [];
  state.step2.form.headingLines.value = 1;
  state.step2.form.footerLines.value = 0;

  state.step3.attributesList = [];
  state.step3.filtredAttributesList = [];
  state.step3.filtredAttributesList = [];

  state.step3.locales.value = 'fr_FR';
  state.step3.tagFilter.value = '';
};

export const resetTemplate = (state) => {
  state.step1.file.table = deepClone(state.step1.file.parsed);

  state.step2.form.encoding.value = 'utf8';
  state.step2.form.separator.value = '';
  state.step2.form.checkbox.value = [];
  state.step2.form.headingLines.value = 1;
  state.step2.form.footerLines.value = 0;

  state.step3.attributesList = [];
  state.step3.draggableRow = Array.from(
    new Array(state.step1.file.parsed[0].length),
    () => [],
  );
  state.step3.filtredAttributesList = [];
  state.step3.filtredAttributesList = [];
  state.step3.locales.value = 'fr_FR';
  state.step3.tagFilter.value = '';
};

// setCurrentStep
export const setCurrentStep = (state, value) => {
  state.currentStep = value;
};

// STEP 1
export const setFile = (state, data) => {
  if (data.parsed) {
    state.step1.file.parsed = data.parsed;
    state.step1.file.parsedLen = data.parsed.length;
    state.step1.file.table = deepClone(data.parsed);
    state.step3.draggableRow = Array.from(new Array(data.parsed[0].length), () => []);
  }
  if (data.uploaded) {
    state.step1.file.uploaded = data.uploaded;
  }
};

export const setTemplate = (state, value) => {
  state.template.value = value;
  state.template.canSave = false;
};

export const setImportsTemplates = (state, { data }) => {
  if (data) {
    state.template.canSave = false;
    state.template.data = data.filter((d) => d.frontfriendly);
    state.template.select = [
      {
        label: 'New Template',
        value: null,
      },
      ...state.template.data.map((d) => ({
        label: d.name,
        value: d.name,
      })),
    ];
  }
};

const isExpanded = (elem) => elem && elem[0] && elem[0].expanded;

const getColspan = (attribute) => {
  const twoColAttribute = ['pricing', 'interval'];
  return attribute && twoColAttribute.includes(attribute.type);
};

const getRealIndex = (state, index) => {
  let corrector = 0;
  for (let i = 0; i < index; i += 1) {
    if (isExpanded(state.step3.draggableRow[i])) {
      corrector += 1;
    }
  }
  return index + corrector;
};

const getMapping = (state) => {
  const isFourColumn = (attribute) => {
    const twoColAttribute = ['pricing', 'interval'];
    return attribute && attribute && twoColAttribute.includes(attribute.type);
  };

  const atrrIsExpanded = (elem) => elem && elem && elem.expanded;
  const atrrGetRealIndex = (index) => {
    let corrector = 0;
    for (let i = 0; i < index; i += 1) {
      if (atrrIsExpanded(state.step3.draggableRow[i][0])) {
        if (isFourColumn(state.step3.draggableRow[i][0])) {
          corrector += 2;
        } else {
          corrector += 1;
        }
      }
    }
    return index + corrector;
  };
  const getColumnsFromAttribute = (attribute, index) => {
    if (atrrIsExpanded(attribute)) {
      const realIndex = atrrGetRealIndex(index);
      if (isFourColumn(attribute)) {
        return [realIndex, realIndex + 1, realIndex + 2, realIndex + 3];
      }
      return [realIndex, realIndex + 1];
    } if (isFourColumn(attribute)) {
      const realIndex = atrrGetRealIndex(index);
      return [realIndex, realIndex + 1];
    }
    return [atrrGetRealIndex(index)];
  };
  return state.step3.draggableRow.reduce((accumulator, current, index) => {
    if (
      current[0]
      && current[0].type !== 'interval-to'
      && current[0].type !== 'price-unit'
    ) {
      const name = current[0].importedtag ? `${current[0].name}@${current[0].importedtag}` : current[0].name;
      accumulator[name] = getColumnsFromAttribute(current[0], index);
    }
    return accumulator;
  }, {});
};
export const setFileId = (state, v) => {
  state.fileId = v;
};

export const checkTemplate = (state) => {
  const template = state.template.data.find((t) => t.name === state.template.value);
  if (template) {
    const mappedData = {
      config: {
        format: {
          locale: state.step3.locales.value,
          encoding: state.step2.form.encoding.value,
          separator: state.step2.form.separator.value,
          headingLines: state.step2.form.headingLines.value,
          defaultCurrency: state.step3.currencies.value,
        },
        mapping: getMapping(state),
      },
      options: {
        noinsert: state.step2.form.checkbox.value.includes('noinsert'),
        noupdate: state.step2.form.checkbox.value.includes('noupdate'),
      },
    };

    const originalData = {
      config: {
        format: template.config.format,
        mapping: template.config.mapping,
      },
      options: template.options,
    };

    if (equal(mappedData, originalData)) {
      state.template.canSave = false;
    } else {
      state.template.canSave = true;
    }
  }
};

// STEP 2
export const setStep2 = (state, data) => {
  if (data.encoding) {
    state.step2.form.encoding.value = data.encoding;
  }
  if (data.separator) {
    state.step2.form.separator.value = data.separator;
  }
  if (data.checkbox) {
    state.step2.form.checkbox.value = data.checkbox;
  }
  if (data.noinsert) {
    state.step2.form.noinsert.value = data.noinsert;
  }
  if (data.headingLines) {
    state.step2.form.headingLines.value = data.headingLines;
  }
  if (data.footerLines) {
    state.step2.form.footerLines.value = data.footerLines;
  }
};

// STEP 3
export const setStep3 = (state, data) => {
  if (data.format && data.format.defaultCurrency) {
    state.step3.currencies.value = data.format.defaultCurrency;
  }
  if (state.step1.file.uploaded.type !== 'text/csv') {
    state.step3.locales.value = 'en_US';
  } else if (data.format && data.format.locale) {
    state.step3.locales.value = data.format.locale;
  }

  if (data.attributesList) {
    state.step3.attributesList = Object.values(data.attributesList)
      .filter((attribute) => {
        for (let i = 0; state.step3.draggableRow[i]; i += 1) {
          // ..
          if (
            state.step3.draggableRow[i][0]
            && state.step3.draggableRow[i][0].name === attribute.name
          ) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => strcmp(a.type, b.type));
    state.step3.filtredAttributesList = state.step3.attributesList;
  }

  if (data.draggableRow) {
    state.step3.draggableRow = data.draggableRow;
  }
};

export const updateTagFilter = (state, data) => {
  state.step3.tagFilter.value = data.value;
  state.step3.filtredAttributesList = state.step3.attributesList.filter((attribute) => data
    .localized(attribute.label)
    .toLowerCase()
    .includes(data.value.toLowerCase())); // add check by name
};

export const setExpanded = (state, data) => {
  state.step3.draggableRow[data.index][0].expanded = data.value;
};

export const mergeColumn = (state, { columnIndex, nosplice }) => {
  const { file } = state.step1;
  file.table = file.table.map((array, rowIndex) => {
    const columns = [array[columnIndex], array[columnIndex + 1]];
    if (columns.some((v) => v === undefined)) {
      array[columnIndex] = columns[0] || columns[1];
    } else if (
      rowIndex < state.step2.form.headingLines.value
      || rowIndex >= file.table.length - state.step2.form.footerLines.value
    ) {
      array[columnIndex] = columns.join(', ');
    } else {
      array[columnIndex] = columns.join(' ');
    }
    return array.filter((elem, index) => index !== columnIndex + 1);
  });
  if (!nosplice) state.step3.draggableRow.splice(columnIndex + 1, 1);
  return true;
};

export const unmergeColumn = (state, columnIndex) => {
  const { file } = state.step1;
  const realcolumnIndex = getRealIndex(state, columnIndex);
  file.table = file.table.map((row, rowIndex) => {
    row.splice(
      columnIndex,
      1,
      file.parsed[rowIndex][realcolumnIndex],
      file.parsed[rowIndex][realcolumnIndex + 1],
    );
    return row;
  });
};

export const moveAttributeRight = (state, index) => {
  state.step3.draggableRow[index + 1] = state.step3.draggableRow[index];
  state.step3.draggableRow.splice(index, 1, []);
};

export const setdraggableRow = (state, data) => {
  if (getColspan(data.value)) {
    const nextAttribute = state.step3.draggableRow[data.index + 1];
    if (nextAttribute && nextAttribute[0]) {
      Notify.create("Conflict please be shure the next column dosen't have attribute");
      return;
    }
  }
  state.step3.attributesList = state.step3.attributesList.filter((attribute) => attribute.label !== data.value.label);
  data.value.expanded = false;
  state.step3.draggableRow[data.index].push(data.value);
  if (data.value.type === 'pricing') {
    const secondAttribute = {
      expanded: false,
      label: 'price-unit',
      name: 'price-unit',
      type: 'price-unit',
    };
    state.step3.draggableRow[data.index + 1].push(secondAttribute);
  }
  if (data.value.type === 'interval') {
    const secondAttribute = {
      expanded: false,
      label: 'interval-to',
      name: 'interval-to',
      type: 'interval-to',
    };
    state.step3.draggableRow[data.index + 1].push(secondAttribute);
  }
};

export const dropdraggableRowElem = (state, columnIndex) => {
  if (
    state.step3.draggableRow[columnIndex][0].type !== 'interval-to'
    && state.step3.draggableRow[columnIndex][0].type !== 'price-unit'
  ) {
    state.step3.attributesList.push(state.step3.draggableRow[columnIndex][0]);
  }
  state.step3.draggableRow[columnIndex].splice(0);
};

export const spliceDraggableRow = (state, data) => {
  state.step3.draggableRow.splice(data.index, data.size, data.value);
};
