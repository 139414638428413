<template>
  <q-dialog
    v-model="localModel"
    prevent-close
    @ok="onOk"
    @cancel="onCancel"
    @show="onShow"
    @hide="onHide"
  >
      <q-card class="dialog">

          <q-card-section class="row items-center">
            <q-toolbar class="bg-primary shadow2 text-white">
              <q-toolbar-title>{{$t('Create a new family')}}</q-toolbar-title>
            </q-toolbar>
          </q-card-section>

          <q-card-section class="items-center card-body">
            <q-input :error="$v.form.name.$error" bottom-slots :error-message="getError('name')"
                v-model="form.name"
                type="text"
                :label="$t('Name')"
                @input="$v.form.label.$touch"
              />
            <localizedstring :label="$t('Label')" name="label" editable="yes" :def="{name: 'label'}" :data="{ v: form.label }"
            :rules="rules" @change="dochange" />
            <q-select bottom-slots filled square dense v-model="form.method" :options="pricingmethods" name="Method"  type="text" label="Method"/>
            <TreeSelect class="treeSelect" :placeholder="$t('Select Parent')" @select="selectParentFamily" style="margin-top:30px" />
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat :label="$t('Cancel')" color="primary" v-close-popup />
            <q-btn flat :label="$t('Create')" color="positive"  @click="onOk"/>
          </q-card-actions>

      </q-card>

  </q-dialog>
</template>

<script>

import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import pricingMethods from '@cabinetmariel/pricing-methods';
import { can } from '@cabinetmariel/scopes';
import localization from '../../../../lib/mixins'; //eslint-disable-line
import localizedstring from '../../../../components/attributesDisplay/localizedstring';
import TreeSelect from '../../../../components/treeSelect/TreeSelect.vue';

const { methods } = pricingMethods;
const localizedMinLength = (length) => (value) => Object.values(value).every((v) => (v.length >= length));

export default {
  props: ['model', 'selected'],
  mixins: [localization],
  components: {
    localizedstring,
    TreeSelect,
  },
  data() {
    return {
      localModel: this.model,
      rules(fullmodel) {
        return [
          (val) => Object.entries(fullmodel).filter(([k, v]) => !!v).every(([k, v]) => v.length > 3)
            || `Label cannot be shorter than 3 characters, ${val}`,
          (val) => (Object.entries(fullmodel).filter(([k, v]) => !!v).length >= 1)
            || 'At least a label in one language is required',
        ];
      },
      form: {
        name: null,
        label: null,
        method: null,
        type: {
          model: null,
          options: [
            { label: 'String', value: 'string' },
            { label: 'Localizedstring', value: 'localizedstring' },
            { label: 'Date', value: 'date' },
            { label: 'Interval', value: 'interval' },
            { label: 'Number', value: 'number' },
            { label: 'Amount', value: 'amount' },
            { label: 'Pricing', value: 'pricing' },
            { label: 'Boolean', value: 'boolean' },
          ],
        },
        weight: [],
        options: [],
      },
      action: null,
      id: null,
      type: 'new',
      locale: this.$store.state.auth.locale,
    };
  },
  computed: {
    pricingmethods() {
      return Object.keys(methods);
    },
  },
  mounted() {
    if (!can('segmentation', this.$store.state.auth.user.scopes)) {
      this.$q.notify({ type: 'done', message: 'Access to segmentation is READ ONLY ([segmentation.read] rights)', icon: 'warning' });
    }
  },
  validations: {
    form: {
      label: { required, minLength: localizedMinLength(3) },
      name: { required },
      method: { required },
    },
  },
  watch: {
    // Watch for changes in prop to update local model
    model(newVal) {
      this.localModel = newVal;
    },
    // Also watch for changes in localModel to inform the parent
    localModel(newVal) {
      this.$emit('update:model', newVal);
    },
  },
  methods: {
    onOk(data) {
      console.log('onOk', data);

      if (this.id === null) {
        this.$q.notify({ type: 'negative', message: 'Please select a parent family', icon: 'warning' });
        return;
      }

      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const params = {
          label: this.form.label,
          name: this.form.name,
          method: this.form.method,
        };
        params.parent = this.id;

        this.$axios.post(`/${this.type}`, params).then((response) => {
          this.form.label = response.data.data.label;
          this.form.name = response.data.data.name;
          this.form.secured = response.data.data.secured;
          console.log('New family created', response.data.data);
          // moveParts
          this.$emit('confirm', { selectedFamily: response.data.data.id });
          this.onHide();
        }).catch((error) => {
          console.error('Error creating family', error);
          this.$q.notify({ type: 'negative', message: 'Error creating family', icon: 'warning' });
        });
      }
    },
    onCancel() {
      this.localModel = false;
    },
    onShow() {},
    onHide() {
      this.localModel = false;
    },

    moveParts(selection) {
      this.$store.dispatch('clipboard/moveParts', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      // this.$store.dispatch('clipboard/moveFamilies', { from: this.$store.state.clipboard.selection.id, to: selection.id });
      // this.$store.dispatch('clipboard/cloneSegmentation', { from: this.$store.state.clipboard.selection.id, to: selection.id });
    },
    getError(elem) {
      return '3 character minimun.';
    },
    dochange(v) {
      this.form.label = v.label;
      this.$v.form.label.$touch();
    },
    selectParentFamily(id) {
      this.id = id;
    },

    initAttribute() {
      this.form.label = { [this.$store.state.auth.locale]: '' };
      this.form.name = null;
      this.form.type.model = null;
      this.form.weight = [];
      this.form.options = [];
    },
    initData(route) {
      this.action = 'new';
      this.type = 'families';
      this.selectParentFamily(route);
      this.initAttribute();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initData(to);
    next();
  },
  created() {
    this.initData(this.$route);
  },
};
</script>

<style lang="stylus" scoped>
.dialog {
  width : 50%;
  max-width: 500px;
  min-width 300px;
}

.card-body{

}

.checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.locked {
  cursor: pointer;
}

.validation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*
  padding: 1rem;
  */
  margin-top: 10px;
}
.localizededition {
  margin-bottom: 25px;
}
.treeSelect {
  max-height: 300px;
}
.content {
  padding: 1rem;
}
</style>
