import axios from 'axios';
import { update } from 'lodash';
import { localization } from '../../lib/mixins';
import putils from '../../lib/pictures';

const stateLoc = {
  selectedSegmentation: null,
  currentFilterId: null,
  defaultSegmentation: null,
  segmentationsList: [],
  history: {
    families: [],
    values: [],
    material: [],
  },
  filters: [],
  showNotificationCb: null,
};

const gettersLoc = {
  mappedSegmentationsList: (state) => state.segmentationsList.map((segmentation) => ({
    id: segmentation.id,
    label: segmentation.name,
  })),
  selectedSegmentationId: (state) => (state.selectedSegmentation ? state.selectedSegmentation.id : null),
  selectionMessage1: (state) => {
    if (state.selectedSegmentation === null) {
      return 'Please select a segmentation';
    }
    return null;
  },
  selectionMessage2: (state, getters, rootState) => (rootState.catalog.selected.length ? `${rootState.catalog.selected.length} Parts selected` : 'No parts selected'),
};

const actions = {
  async init({ rootState, state, dispatch }, showNotificationCb) {
    await dispatch('fetchSegmentations');
    state.selectedSegmentation = rootState.catalog.selectedSegmentation;
    state.showNotificationCb = showNotificationCb.bind(this);

    // dispatch('showNotification', { type: 'positive', message: 'Segmentation module initialized', icon: 'done' });
  },
  showNotification({ state }, { type, message, icon }) {
    console.log('[showNotification] ', type, message, icon);
    if (typeof state.showNotificationCb === 'function') {
      state.showNotificationCb(type, message, icon);
    }
  },

  // SEGMENTATIONS
  async fetchSegmentations({ rootState, state, commit }) {
    console.log('[Segmentation store] fetchSegmentations');
    try {
      const resSegm = await axios.get('/segmentations');
      state.segmentationsList = resSegm.data.data;

      const resFilters = await axios.get('/filters', { params: { nolimit: 'yes' } });
      state.filters = resFilters.data.data;

      // Find "DEFAULT" segmentation
      const defaultSegmentation = resSegm.data.data.find((s) => s.name === 'DEFAULT');
      if (defaultSegmentation) {
        state.defaultSegmentation = defaultSegmentation;
      }
    } catch (error) {
      console.error(error);
    }
  },
  parseFilters({ rootState }) {
    return JSON.stringify(rootState.catalog.filters.reduce((acc, f) => {
      let { value } = f;
      if (!Array.isArray(value)) value = [value];
      Array.prototype.push.apply(acc, value.map((v) => ({
        column: f.name,
        op: v.action,
        args: Array.isArray(v.value) ? v.value : [v.value],
      })));
      Array.prototype.push.apply(acc, value.filter((v) => !!v.noempty).map((v) => ({
        column: f.name,
        op: 'not_empty',
        args: [],
      })));
      return acc;
    }, []));
  },
  hasFilters({ rootState }) {
    return rootState.catalog.filters.length > 0;
  },
  async createSegmentation({ state, dispatch }, newSegmentation) {
    if (!newSegmentation) {
      dispatch('showNotification', { type: 'negative', message: 'Please enter a name for the new segmentation', icon: 'warning' });
      return;
    }

    let url = '/segmentations';
    const hasFilters = await dispatch('hasFilters');
    if (hasFilters) {
      const filters = await dispatch('parseFilters');
      console.log('[Segmentation store] hasFilters', filters);
      url += `?@filters=${filters}`;
    }
    console.log('url', url);

    try {
      const response = await axios.post(url, { name: newSegmentation });
      await dispatch('fetchSegmentations');
      state.selectedSegmentation = response.data.data.id;
    } catch (error) {
      console.error(error);
    }
  },
  async applySegmentation({ state, dispatch }, selectedSegmentation) {
    if (!selectedSegmentation) {
      dispatch('showNotification', { type: 'negative', message: 'Please select a segmentation to apply', icon: 'warning' });
      return;
    }

    console.log('[Segmentation store] applySegmentation', selectedSegmentation);
    try {
      await axios.post(`/segmentations/${selectedSegmentation}/apply`);
      dispatch('showNotification', { type: 'positive', message: 'Segmentation applied', icon: 'done' });
      // dispatch('updateSegmentation');
    } catch (error) {
      console.error(error);
      dispatch('showNotification', { type: 'negative', message: 'Segmentation cannot be applied', icon: 'warning' });
    }
  },
  async deleteSegmentation({ state, dispatch }, selectedSegmentation) {
    if (!selectedSegmentation) {
      dispatch('showNotification', { type: 'negative', message: 'Please select a segmentation to delete', icon: 'warning' });
      return;
    }

    console.log('[Segmentation store] deleteSegmentation', selectedSegmentation);
    try {
      await axios.delete(`/segmentations/${selectedSegmentation}`);
      dispatch('showNotification', { type: 'positive', message: 'Segmentation deleted', icon: 'done' });
      await dispatch('fetchSegmentations');
      state.selectedSegmentation = null;
    } catch (error) {
      console.error(error);
      dispatch('showNotification', { type: 'negative', message: 'Segmentation cannot be deleted', icon: 'warning' });
    }
  },
  async updateSegmentation({
    rootState, state, commit, dispatch,
  }) {
    rootState.catalog.selectedSegmentation = state.selectedSegmentation;

    await dispatch('fetchSegmentations', state.selectedSegmentation);

    if (!state.selectedSegmentation) return null;
    const selectedSegmentationObj = state.segmentationsList.find((s) => s.id === state.selectedSegmentation);
    if (!selectedSegmentationObj) {
      console.warn(`Segmentation with id ${state.selectedSegmentation} not found in segmentationsList`);
      return null;
    }

    // History
    const currentHistory = state.history;
    if (!currentHistory.length && selectedSegmentationObj.config?.history) state.history = selectedSegmentationObj.config?.history;

    return selectedSegmentationObj;
  },

  // FAMILIES
  async getFamiliesImgs({ rootState, state }, id) {
    const columns = ['pictures'].join(',');
    const response = await axios.get('/products', {
      params: {
        columns, '@limit': 1, '@offset': 0, '@filters': JSON.stringify([{ column: 'pictures', op: 'not_empty', args: [] }, { column: 'families', op: 'belongsto', args: [id] }]), segmentation: state.selectedSegmentation,
      },
    });
    const { data } = response.data;
    if (data && data.length) {
      return putils.mkpath(data[0]?.pictures?.v[0], rootState.auth.token);
    }
    return '/default.png';
  },

  async getFamilies({ dispatch }, id = null) {
    if (!id) {
      const response = await axios.get('/families', { params: { nolimit: 'yes', sort: 'label' } });
      const tmpData = await Promise.all(response.data.data.map(async (f) => ({
        id: f.id,
        parent: f.parent ? f.parent : null,
        label: localization.methods.localized(f.label, 'en-US'),
        name: f.name,
        leaf: f.leaf,
        img: await dispatch('getFamiliesImgs', f.id),
      })));
      return tmpData;
    }

    const response = await axios.get(`/families/${id}/siblings`, { params: { nolimit: 'yes', sort: 'label' } });
    const tmpData = await Promise.all(response.data.data.map(async (f) => ({
      id: f.id,
      parent: f.parent ? f.parent : null,
      label: localization.methods.localized(f.label, 'en-US'),
      name: f.name,
      leaf: f.leaf,
      img: await dispatch('getFamiliesImgs', f.id),
    })));
    return tmpData;
  },
  async updateFamily({ state, dispatch }) {
    await Promise.all(state.history.families.map((f) => dispatch('getFamilies', f.id)));
  },
  async deleteFamily({ state, commit, dispatch }, familyId) {
    console.log('[Segmentation store] deleteFamily', familyId);

    if (familyId === state.defaultSegmentation) {
      dispatch('showNotification', { type: 'negative', message: 'You cannot delete the default segmentation', icon: 'warning' });
      return;
    }

    try {
      await axios.delete(`/families/${familyId}`);
      dispatch('showNotification', { type: 'positive', message: 'Family got deleted', icon: 'done' });
      state.history.families = state.history.families.filter((f) => f.id !== familyId);
      state.selectedSegmentation = state.defaultSegmentation;
      // dispatch('updateSegmentation');
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'Something wrong happened trying to delete the family', icon: 'warning' });
      // dispatch('updateSegmentation');
    }
  },
  async move({ state, dispatch }, { parts, familyId }) {
    console.log('[Segmentation store] move', parts, familyId);
    if (!parts.length) {
      dispatch('showNotification', { type: 'negative', message: 'Please select at least one part to move', icon: 'warning' });
      return;
    }

    if (!familyId) {
      dispatch('showNotification', { type: 'negative', message: 'Please select a family to move the part(s) to', icon: 'warning' });
      return;
    }

    try {
      await axios.patch(`/segmentations/${state.selectedSegmentation}/move`, {
        family: familyId,
        products: parts.map((item) => item.id),
      });
      dispatch('showNotification', { type: 'positive', message: 'Part(s) got moved', icon: 'done' });
      // dispatch('updateSegmentation');
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'Something wrong happened trying to move the part(s)', icon: 'warning' });
      // dispatch('updateSegmentation');
    }
  },

  // MATERIAL
  async addMaterial({ dispatch }, { ids, params }) {
    console.log('[Segmentation store] addMaterial', ids, params);

    try {
      console.log('/products params', params);
      const res = await axios.patch('/products', params);
      // dispatch('updateSegmentation');
      dispatch('showNotification', { type: 'positive', message: `Materials have been added for ${ids.length} products`, icon: 'done' });
      return res;
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'The attribute cannot be updated', icon: 'warning' });
      return null;
    }
  },

  // VALUE

  // HISTORY FAMILY
  async addHistoryFamilies({ state, dispatch }, { familyId, selectedSegmentation }) {
    const res = await dispatch('getFamilies', familyId);
    const family = res.find((f) => f.id === familyId);

    state.history.families.unshift(family);

    axios.patch(`/segmentations/${selectedSegmentation}`, {
      config: {
        history: state.history,
      },
    });
  },
  async removeHistoryFamilie({ state, dispatch }, { familyId, selectedSegmentation }) {
    state.history.families = state.history.families.filter((f) => f.id !== familyId);
    axios.patch(`/segmentations/${selectedSegmentation}`, {
      config: {
        history: state.history,
      },
    });
  },

  // HISTORY MATERIAL
  async addHistoryMaterial({ rootState, state, dispatch }, params) {
    console.log('[Segmentation store] addHistoryMaterial', params);

    const attributes = Object.values(rootState.pricing.defs.defs);
    // Iterate over the keys in params
    Object.keys(params).forEach((key) => {
      // Check if the value is true
      if (params[key] === true) {
        console.log('addHistoryMaterial', params);
        // Find the attribute with the name matching the key
        const material = attributes.find((att) => att.name === key);
        console.log('material', material);
        // Check if the material is already in the history
        const exists = state.history.material.some((m) => m.name === key);
        if (!exists && material) {
          state.history.material.unshift(material);
          console.log('history.material', state.history.material);
          if (state.history.material.length > 5) {
            state.history.material.pop();
          }
        }
      }
    });
  },
  async removeHistoryMaterial({ state, dispatch }, materialId) {
    console.log('[Segmentation store] removeHistoryMaterial', materialId);
    state.history.material = state.history.material.filter((m) => m.id !== materialId);
  },

  // HISTORY VALUE
  async addHistoryValues({ rootState, state, dispatch }, params) {
    console.log('[Segmentation store] addHistoryValues', params);
    const attributes = Object.values(rootState.pricing.defs.defs);

    // Iterate over the keys in params
    Object.keys(params).forEach((key) => {
      // Check if the value is true
      if (params[key] === true) {
        console.log('addHistoryValues', params);
        // Find the attribute with the name matching the key
        const value = attributes.find((att) => att.name === key);
        console.log('value', value);
        // Check if the value is already in the history
        const exists = state.history.values.some((v) => v.name === key);
        if (!exists && value) {
          state.history.values.unshift(value);
          console.log('history.values', state.history.values);
          if (state.history.values.length > 5) {
            state.history.values.pop();
          }
        }
      }
    });
  },
  async removeHistoryValue({ state, dispatch }, valueId) {
    console.log('[Segmentation store] removeHistoryValue', valueId);
    state.history.values = state.history.values.filter((v) => v.id !== valueId);
  },

  // FILTERS
  async saveFilters({ rootState, state, dispatch }, filters) {
    console.log('[Segmentation store] saveFilters', filters);

    state.filters = filters;

    const selectedSegmentationObj = state.segmentationsList.find((s) => s.id === state.selectedSegmentation);
    if (!selectedSegmentationObj) return;
    const { filter } = selectedSegmentationObj;

    try {
      await axios.put(`/filters/${filter}`, { filter: { filters: rootState.catalog.filters, segmentation: selectedSegmentationObj.id } });
      await dispatch('fetchSegmentations');
      dispatch('showNotification', { type: 'positive', message: 'Filters have been saved', icon: 'done' });
    } catch (error) {
      dispatch('showNotification', { type: 'negative', message: 'Filters cannot be saved', icon: 'warning' });
    }
  },

};

export default {
  namespaced: true,
  state: stateLoc,
  getters: gettersLoc,
  mutations: null,
  actions,
};
