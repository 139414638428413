<template>
  <q-dialog v-model="localModel" prevent-close @ok="onOk" @cancel="onCancel" @show="onShow" @hide="onHide">

    <q-card class="dialog">
        <attributesDetails
        :standaloneMode="true"
        :inAction="'new attributes'"
        :inColumn="0"
        :set="set"
        @onSave="onOk"
        @onCancel="onCancel"
        />
    </q-card>

  </q-dialog>
</template>

<script>
import { localization } from '../../../../lib/mixins';
import attributesDetails from '../../../../components/drawer/attributes/attributesDrawer.vue';

export default {
  props: {
    model: Boolean,
    selected: Array,
    set: String,
  },
  mixins: [localization],
  components: {
    attributesDetails,
  },
  data() {
    return {
      localModel: this.model,
    };
  },
  computed: {},
  watch: {
    model(newVal) {
      this.localModel = newVal;
    },
    localModel(newVal) {
      this.$emit('update:model', newVal);
    },
  },
  methods: {
    onOk(data) {
      console.log('create attr dialog data', data);
      this.$emit('confirm', data);
    },
    onCancel() {
      this.localModel = false;
    },
    onShow() {},
    onHide() {
      this.localModel = false;
    },
  },
  created() {},
};
</script>

<style scoped lang="stylus">
.dialog{
  min-width : 500px;
  width: 100%;
  max-width: 50%;
  max-height: 80%;
}
</style>
