<template>
  <div class="treeSelect">
    <div class="searchDiv">
      <input class="search" type="text" :placeholder="placeholder" v-model="selectedFamily" @input="search" :disabled="disabled"/>
      <q-btn flat dense icon="arrow_drop_down" class="icon" @click="LoadAll()"/>
    </div>
    <div class="tree" id ="tree">
      <TreeSelectItem
        class="first"
        :disabled="disabled"
        :data="data"
        :parent="null"
        :maxDeep="maxDeep"
        :currentDeep="0"
        :loadChild="loadChild"
        @input="select"
        :showConfirm="showConfirm"
        @setConfirm="setConfirm"
      />
    </div>

    <div v-if="imgs" class="carousel">
      <div class="topLine">
        <p class="title">{{$t('Family pictures:')}}</p>
        <q-btn flat round dense icon="expand" class="ico" @click="carouselBig= true"/>
      </div>
      <slot :part="{ ...currentImg, next, prev }"></slot>
    </div>

    <div v-if="imgs & carouselBig" class="carouselBig">
      <p class="title">{{$t('Family pictures:')}}</p>
      <q-btn flat round dense icon="cancel" class="cross" @click="carouselBig= false"/>
      <slot :part="{ ...currentImg, next, prev }"></slot>
    </div>

  </div>
</template>

<script>
import TreeSelectItem from './TreeSelectItem.vue';
import { localization } from '../../lib/mixins';

function haspicture(p) {
  if (p?.pictures?.v?.[0]) return true;
  return false;
}
export default {
  name: 'TreeSelect',
  data() {
    return {
      selectedFamily: null,
      ignoreNextChange: false,
      maxDeep: 5,
      data: [],
      imgsData: [],
      currentImg: {},
      currentImgId: 0,
      carouselBig: false,
      showConfirm: null,
    };
  },
  mixins: [localization],
  components: { TreeSelectItem },
  emits: ['input', 'select'],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    filter: {
      type: Boolean,
      required: false,
      default: false,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    imgs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    selectedFamily() {
      if (this.ignoreNextChange) {
        this.ignoreNextChange = false;
        return;
      }
      this.search();
    },
    /*
    value() {
      this.data = [];
      this.loopOnParent(this.value);
    },
    */
  },
  mounted() {
    if (this.value) {
      this.loopOnParent(this.value);
      this.getFamiliesImgs(this.value);
    } else {
      this.LoadAll();
    }
  },
  methods: {
    setConfirm(value) {
      this.showConfirm = value;
    },
    getFamiliesImgs(id) {
      if (!this.imgs) return;
      const columns = [...this.columns, 'pictures'].join(',');
      this.$axios.get(`/families/${id}/parts`, { params: { columns, '@limit': 50, '@offset': 0 } })
        .then((response) => {
          this.imgsData = this.filter ? response.data.data.filter((p) => haspicture(p)) : response.data.data;
          this.currentImgId = 0;
          if (this.imgsData.length > 0) {
            this.currentImg = this.imgsData[this.currentImgId];
          } else {
            this.currentImg = {};
          }
          this.$forceUpdate();
        });
    },
    prev() {
      if (this.imgsData.length === 0) return;
      if (this.currentImgId === 0) {
        this.currentImgId = this.imgsData.length - 1;
      } else {
        this.currentImgId -= 1;
      }
      this.currentImg = this.imgsData[this.currentImgId];
    },
    next() {
      if (this.imgsData.length === 0) return;
      if (this.currentImgId === this.imgsData.length - 1) {
        this.currentImgId = 0;
      } else {
        this.currentImgId += 1;
      }
      this.currentImg = this.imgsData[this.currentImgId];
    },
    search() {
      if (this.disabled) return;

      const tmpSearch = this.selectedFamily?.split(/\s+/).map((w) => `${w}:*`).join(' ');

      if (this.selectedFamily && (this.selectedFamily.length < 3 || tmpSearch.length < 3)) {
        this.loadChild(null);
        return;
      }

      if ( tmpSearch.length < 3)  return;
      this.$axios.get('/families', { params: { nolimit: 'yes', sort: 'label', search: tmpSearch } })
        .then((response) => {
          const tmpData = response.data.data.map((f) => ({
            id: f.id,
            parent: f.parent ? f.parent : null,
            label: this.localized(f.label),
            name: f.name,
            rank: f.rank,
            leaf: f.leaf,
            collapsed: false,
            select: false,
          }));
          this.sortAndClean(tmpData);
        });
    },
    LoadAll() {
      if (this.disabled) return;
      this.selectedFamily = '';
      this.loadChild(null);
    },
    loopOnParent(id, label = '?') {
      if (!id || id === null) {
        this.$nextTick(() => {
          const elmnt = document.getElementById(this.value);
          if (elmnt) elmnt.scrollIntoView();
        });
        /*
        setTimeout(() => {
          const elmnt = document.getElementById(this.value);
          if (elmnt) elmnt.scrollIntoView();
        }, 200); */
        return;
      }
      this.$axios.get(`/families/${id}/siblings`, { params: { nolimit: 'yes', sort: 'label' } })
        .then((response) => {
          const newData = response.data.data.map((f) => ({
            id: f.id,
            parent: f.parent ? f.parent : null,
            label: this.localized(f.label),
            leaf: f.leaf,
            collapsed: true,
            select: false,
            secured: f.secured,
          }));

          const index = newData.findIndex((f) => f.id === id);
          this.ignoreNextChange = true;
          if (label === '?') {
            this.selectedFamily = newData[index].label;
            newData[index].select = true;
            newData[index].collapsed = true;
          } else {
            newData[index].collapsed = false;
          }
          this.sortAndClean([...this.data, ...newData]);
          this.loopOnParent(newData[0].parent, newData[0].label);
        });
    },
    loadChild(id) {
      if (!id || id === null) {
        this.data = [];
        this.$axios
          .get('/families', { params: { nolimit: 'yes', sort: 'label' } })
          .then((response) => {
            const newData = response.data.data.map((f) => ({
              id: f.id,
              parent: f.parent ? f.parent : null,
              label: this.localized(f.label),
              leaf: f.leaf,
              collapsed: true,
              select: false,
            }));
            this.sortAndClean([...this.data, ...newData]);
          });
      } else {
        // console.log(`load child of ${id}`);
        this.$axios
          .get(`/families/${id}/children`)
          .then((response) => {
            const newData = response.data.data.map((f) => ({
              id: f.id,
              parent: f.parent ? f.parent : null,
              label: this.localized(f.label),
              leaf: f.leaf,
              collapsed: true,
              select: false,
              secured: f.secured,
            }));
            this.sortAndClean([...this.data, ...newData]);
          });
      }
    },
    sortAndClean(newData) {
      // remove duplicated id
      newData = newData.filter((f) => f.id !== 'id3');
      const ids = newData.map(({ id }) => id);
      newData = newData.filter(({ id }, index) => !ids.includes(id, index + 1));

      // loop on all item
      newData = newData.map((item) => {
        item.label = this.localized(item.label).normalize('NFD');
        return item;
      });

      newData = newData.sort((a, b) => {
        // upper case and remove accent from all labels and compare them
        const labelA = this.localized(a.label).toUpperCase().replace(/[\u0300-\u036f]/g, '');
        const labelB = this.localized(b.label).toUpperCase().replace(/[\u0300-\u036f]/g, '');
        if (labelA > labelB) return 1;
        if (labelA < labelB) return -1;
        return 0;
      });

      this.data = newData;
      this.$forceUpdate();
    },
    select(item) {
      if (this.disabled) return;
      // console.log('select: ', item);
      this.data.forEach((f) => {
        f.select = false;
      });
      if (!item) return;
      this.ignoreNextChange = true;
      this.selectedFamily = item.label;
      item.select = true;

      this.getFamiliesImgs(item.id);
      this.$forceUpdate();
      this.$emit('select', item.id);
      this.$emit('input', item.id);
    },
  },
};
</script>

<style lang="stylus" scoped>

.treeSelect {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.searchDiv{
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top:-10px;
}
.search {
  width: 100%;
  height: 35px;
  border: none;
  padding-left: 10px;
  font-size: 16px;
  margin-left: 2px;
}
.icon {
  margin: 0px;
  padding: 0px;
  width: 50px;
}
.tree {
  border: 1px solid #e0e0e0;
  flex-grow: 1;
  height: calc(100vh - 555px);
  overflow-y: auto;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom:15px;
}

.carouselBig{
  position: fixed;
  width: 90%;
  height: 90%;
  max-width: 600px;
  max-height: 400px;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .cross{
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    padding: 0px;
    width: 30px;
    height: 30px;
  }
  .title {
    font-size: 20px;
    margin-top:10px
  }

  .imgs {
    margin-top:-10px;
    height: 100%;

    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
  }

  .imgs {
    display: flex;
    flex-direction: row;
  }

  .img{
    border-radius: 5px;
    width: 65%;
    cursor: pointer;
    object-fit: contain;
  }
}

.carousel{
   .topLine{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top:5px;
    .ico{
      margin-top:-18px;
      width: 40px;
      height: 40px;
    }
  }
  .imgs {
    margin-top:-10px;
    border: 1px solid #e0e0e0;
    height: 150px;
    overflow-y: auto;
    border-radius: 5px;

    padding: 5px 10px;
    max-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
  }

  .imgs {
    display: flex;
    flex-direction: row;
  }

  .img{
    border-radius: 5px;
    width: 65%;
    cursor: pointer;
    object-fit: contain;
  }
}
</style>
