import { render, staticRenderFns } from "./attributesDrawer.vue?vue&type=template&id=1793002e&scoped=true&"
import script from "./attributesDrawer.vue?vue&type=script&lang=js&"
export * from "./attributesDrawer.vue?vue&type=script&lang=js&"
import style0 from "./attributesDrawer.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./attributesDrawer.vue?vue&type=style&index=1&id=1793002e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1793002e",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBtn,QToolbarTitle,QIcon,QTabs,QTab,QTabPanels,QTabPanel,QInput,QSelect,QList,QItem,QItemSection,QCheckbox,QItemLabel,QTooltip,QRadio});qInstall(component, 'directives', {Ripple});
